import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Rifm } from 'rifm';
import { CustomTooltip } from './Tooltip/CustomTooltip';
import Info from '../../../Icons/Info.svg';
import { FormTextFieldStyled } from './FormTextField';

interface FormKeyboardDateProps {
  name: string;
  label: string;
  tooltipTitle?: string;
}

export const FormKeyboardDate = (props: FormKeyboardDateProps) => {
  const { name, label, tooltipTitle } = props;
  const { control } = useFormContext();

  const parseDigits = (value: string) => (value.match(/\d+/g) || []).join('');

  const formatDate = (value: string) => {
    const digits = parseDigits(value);
    const chars = digits.split('');
    return chars
      .reduce((r, v, index) => (index === 2 || index === 4 ? `${r}-${v}` : `${r}${v}`), '')
      .substr(0, 10);
  };

  const addMask = (value: string) => {
    const digits = parseDigits(value);
    const days = digits.slice(0, 2).padEnd(2, 'TT');
    const months = digits.slice(2, 4).padEnd(2, 'MM');
    const years = digits.slice(4, 8).padEnd(4, 'JJJJ');
    return `${days}.${months}.${years}`;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { invalid, isDirty } }) => {
        return (
          <Rifm
            accept={/[\d]/g}
            mask={true}
            format={formatDate}
            replace={addMask}
            value={value}
            onChange={onChange}>
            {({ value, onChange }) => (
              <FormTextFieldStyled
                error={invalid}
                color={'secondary'}
                onChange={onChange}
                value={value}
                label={label}
                variant={'outlined'}
                type={'text'}
                className={isDirty && !invalid ? 'filledInput' : ''}
                InputProps={{
                  endAdornment: tooltipTitle ? (
                    <CustomTooltip enterTouchDelay={1} title={tooltipTitle} placement={'right'}>
                      <img alt={''} src={Info} className={'tooltipIcon'} />
                    </CustomTooltip>
                  ) : null,
                }}
              />
            )}
          </Rifm>
        );
      }}
    />
  );
};
