import { FormControlLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FormCheckboxStyled } from '../FormCheckbox';
import { LevelBox } from './LevelBox';
import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxRadioListItem, LevelObj } from '../../../../Types/FormFields';
import { Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { SaveFieldsParams } from '../../../../Domain/components/Steps/Languages';

interface CboxRadioListItemProps {
  idx: number;
  radioOptions: string[] | LevelObj[];
  checkbox: CheckboxRadioListItem;
  levelName: string;
  checkboxName: string;
  onChange: any;
  saveFields?(params?: SaveFieldsParams): void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    label: {
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '10px 0',
      margin: 0,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },
    },
    labelOpenRadio: {
      paddingBottom: 0,
    },
    labelChecked: {
      fontWeight: 500,
      fontSize: 16,
      color: '#000',
      '& span': {
        fontWeight: 600,
      },
    },
    labelCheckedLevel: {
      '&.MuiFormControlLabel-root': {
        display: 'flex !important',
        borderBottom: 'unset !important',
      },
    },
  };
});

export const CboxRadioListItem = (props: CboxRadioListItemProps): JSX.Element => {
  const { idx, radioOptions, checkbox, levelName, onChange, checkboxName, saveFields } = props;
  const classes = useStyles();
  const { setValue, getValues, watch } = useFormContext();
  const [openRadio, setOpenRadio] = useState<boolean>(false);

  const isChecked = getValues(checkboxName);

  const handleOpenRadio = useCallback(
    (value: boolean) => setOpenRadio(value),
    [checkboxName, levelName],
  );

  const handleSelectedLabel = (level?: string) => {
    saveFields &&
      saveFields({
        label: checkbox.label,
        level,
        isChecked,
      });
  };

  const handleChangeWithLevel = useCallback((e, onChange, checkboxLevelName) => {
    onChange(e);
    setValue(checkboxLevelName, '');

    setOpenRadio(true);
  }, []);

  useEffect(() => {
    !getValues(levelName) && setOpenRadio(true);
  });

  return (
    <>
      <FormControlLabel
        key={idx}
        className={clsx(classes.label, {
          [classes.labelChecked]: isChecked,
          [classes.labelCheckedLevel]: isChecked,
          [classes.labelOpenRadio]: !openRadio && isChecked,
        })}
        control={
          <FormCheckboxStyled
            color={'secondary'}
            value={checkbox.label}
            checked={isChecked}
            onChange={e => handleChangeWithLevel(e, onChange, levelName)}
          />
        }
        label={checkbox.label}
      />
      {isChecked && (
        <LevelBox
          name={levelName}
          radioOptions={radioOptions}
          open={openRadio}
          handleOpenRadio={handleOpenRadio}
          handleSelectedLabel={handleSelectedLabel}
        />
      )}
    </>
  );
};
