import { CheckboxRadioListItem, GroupOpt } from '../../../../Types/FormFields';
import { EntryPut, GroupPut } from '../../../../Types/RegDataPut';
import { FieldsTypes } from '../../../../Context/StepsReducer';
import { FinalStepForm } from '../../../../Types/Steps';
import { DetailsModelFields } from './interface';

const getEntriesApi = (entries: CheckboxRadioListItem[]) => {
  return entries.map((tech: CheckboxRadioListItem) => ({ label: tech.label, level: tech.level }));
};

export const getFilteredEntriesApi = (entries: CheckboxRadioListItem[]) => {
  const entriesApi = getEntriesApi(entries);

  return entriesApi.filter((tech: EntryPut) => {
    return !!tech.level;
  });
};

const getTechsGroupsApi = (groups: GroupOpt[]) => {
  return groups.map((techGroup: GroupOpt) => {
    return { ...techGroup, entries: getFilteredEntriesApi(techGroup.entries) };
  });
};

export const getFltrGroupsApi = (groups: GroupOpt[]) => {
  const techsGroupsApi = getTechsGroupsApi(groups);
  return techsGroupsApi.filter((techGroup: GroupPut) => {
    return techGroup.entries.length > 0;
  });
};
export const getModulesApi = (
  fields: FieldsTypes,
  finalStepData: DetailsModelFields,
  contract_staffing: number,
) => {
  const technologies = getFltrGroupsApi(fields.techsGroupsOpt);
  const languages = getFilteredEntriesApi(fields.languages);
  const available_from = (finalStepData.available_from as string).split('.').reverse().join('-');

  const competences = fields.competences.map(listItem => listItem.id);
  const regions = finalStepData.regions.map(listItem => listItem.id);
  const sectors = fields.sectors.map(listItem => listItem.id);

  return {
    competences,
    technologies,
    sectors,
    'contact-api-long': {
      phone: finalStepData.areaCode + finalStepData.phone,
      country: finalStepData.country,
      place: finalStepData.residence,
      zip: finalStepData.postCode,
      newsletter: finalStepData.newsletter,
      available_from: available_from,
      days_per_week: finalStepData.days_per_week,
      contract_staffing, //1449
      insurance: finalStepData.insurance ? 1812 : 1813,
      daily_rate: finalStepData.daily_rate,
      notice: finalStepData.comment,
      proBono: finalStepData.pro_bono ? 1880 : 1881,
      regions,
    },
    languages,
  };

  // return {
  //   competences,
  //   technologies,
  //   sectors,
  //   regions,
  //   availability: {
  //     available_from,
  //     days_per_week: finalStepData.days_per_week as number,
  //   },
  //   contact: {
  //     first_name: fields.firstName,
  //     last_name: fields.surname,
  //     email: fields.email,
  //     gender: fields.gender,
  //     area_code: fields.areaCode,
  //     phone: fields.phone as number,
  //     website: fields.website,
  //     place: fields.residence,
  //     zip: fields.postCode as number,
  //     country: fields.country as number,
  //     newsletter: finalStepData.newsletter,
  //     password: fields.password,
  //     xing_url: fields.xing_url,
  //     linkedin_url: fields.linkedin_url,
  //     company: '',
  //   },
  //   contract_staffing,
  //   daily_rate: finalStepData.daily_rate as number,
  //   languages,
  //   notice: fields.comment,
  //   insurance: finalStepData.insurance ? 1812 : 1813,
  //   pro_bono: finalStepData.pro_bono ? 1880 : 1881,
  //   comment: finalStepData.comment,
  // };
};

export const createNumber = () => Math.floor(Math.random() * -9998) + 99999;
