import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ProfileIcon from '../../../../Icons/Profile_picture.svg';

interface DropzoneProps {
  accept: string;
  onDrop: any;
  maxSize?: number;
  supportedFileNames?: string;
  context?: string;
  hasError?: boolean;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    dropzone: {
      padding: '14px 0',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: '4px',
      borderColor: '#CCCCCC',
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      color: '#999999',
      fontSize: 16,
      lineHeight: '20px',
      cursor: 'pointer',
    },
    dropzoneError: {
      color: theme.palette.error.main,
    },

    dropzoneContent: {
      display: 'flex',
      alignItems: 'center',
    },
    profileIcon: {
      marginRight: 12,
    },
  };
});

export const Dropzone = (props: DropzoneProps): JSX.Element => {
  const { onDrop, accept, context, maxSize, hasError = false } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
  });
  const classes = useStyles();

  return (
    <Box
      {...getRootProps()}
      className={clsx(classes.dropzone, { [classes.dropzoneError]: hasError })}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className={classes.dropzoneContent}>
        {context === 'profile' ? (
          <>
            <img src={ProfileIcon} className={classes.profileIcon} />
            Profilbild hinzufügen
          </>
        ) : (
          'Hinzufügen'
        )}
      </div>
    </Box>
  );
};
