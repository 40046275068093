import React, { useState } from 'react';
import clsx from 'clsx';
import { FormCheckboxStyled } from '../../../App/Shared/Form/FormCheckbox';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { HandleUpdateCheckParams } from '../Steps/Competences/Competences';

const useStyles = makeStyles<Theme>(theme => {
  return {
    label: {
      display: 'flex !important',
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '10px 0',
      margin: 0,
      marginLeft: '0 !important',
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },
    },
    levelWrapper: {
      paddingBottom: 10,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    levelBtn: {
      textTransform: 'unset',
      padding: '0 5px',
      color: '#999',
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: 27,
    },
    labelOpenRadio: {
      paddingBottom: 0,
    },
    labelChecked: {
      fontWeight: 500,
      fontSize: 16,
      color: '#000',
      '& span': {
        fontWeight: 600,
      },
    },
    labelCheckedLevel: {
      '&.MuiFormControlLabel-root': {
        borderBottom: 'unset !important',
      },
    },
  };
});

interface CheckBoxProps {
  parent: string;
  label: string;
  id: string;
  checked: boolean;
  value?: boolean;
  onUpdate: (values: HandleUpdateCheckParams) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ parent, id, label, checked, onUpdate }) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckbox = () => {
    onUpdate({
      parent,
      id,
      label,
      value: !isChecked,
    });
    setIsChecked(!isChecked);
  };

  return (
    <>
      <FormControlLabel
        className={clsx(classes.label, {
          [classes.labelChecked]: isChecked,
          [classes.labelCheckedLevel]: isChecked,
        })}
        control={
          <FormCheckboxStyled color="secondary" checked={isChecked} onChange={handleCheckbox} />
        }
        label={label}
      />
    </>
  );
};
