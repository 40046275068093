import { DetailsModelFields } from './interface';
import { CheckboxListItem } from '../../../../Types/FormFields';

export const detailsInitialValues: DetailsModelFields = {
  available_from: '',
  days_per_week: '',
  daily_rate: '',
  postCode: '',
  residence: '',
  country: 2000,
  areaCode: '+49',
  phone: '',
  regions: [],
  comment: '',
  contract_staffing: false,
  newsletter: false,
  insurance: false,
  pro_bono: false,
};
