import { Box, IconButton, Typography } from '@material-ui/core';
import { Dropzone } from './Dropzone';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import RemoveFileIcon from '../../../../Icons/Close-large.svg';
import clsx from 'clsx';
import { DropFieldStyles } from '../Styles/DropFieldStyles';

interface DropFilesFieldProps {
  onDrop: any;
  accept: string;
  name: string;
  label?: string;
  supportedFileNames?: string;
  hasError?: boolean;
}

export const DropFilesField = (props: DropFilesFieldProps): JSX.Element => {
  const { onDrop, supportedFileNames, accept, name, label = 'Dokumente', hasError = false } = props;
  const classes = DropFieldStyles();
  const { watch, setValue, getValues } = useFormContext();
  const dropFiles = watch(name);

  const handleRemoveFile = useCallback(
    (idx: number) => {
      dropFiles.splice(idx, 1);
      setValue(name, dropFiles);
    },
    [name, setValue, dropFiles],
  );

  return (
    <Box className={clsx(classes.root, { [classes.errorLabel]: hasError })}>
      <Typography
        variant={'body2'}
        className={clsx(classes.header, { [classes.errorLabel]: hasError })}>
        {label}
      </Typography>
      {dropFiles.map((uploadedFile: File, idx: number) => {
        return (
          <Box className={clsx(classes.uploadedFile, classes.oneOfUploadedFile)} key={idx}>
            {uploadedFile.name}
            <IconButton onClick={() => handleRemoveFile(idx)} className={classes.clearUploadedFile}>
              <img alt={''} src={RemoveFileIcon} />
            </IconButton>
          </Box>
        );
      })}

      <Dropzone
        supportedFileNames={supportedFileNames}
        onDrop={onDrop}
        accept={accept}
        maxSize={64000000}
      />
      {supportedFileNames && (
        <Typography className={classes.supportedFilesNames}>{supportedFileNames}</Typography>
      )}
    </Box>
  );
};
