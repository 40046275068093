import { Box, Button, makeStyles } from '@material-ui/core';
import { FormGroupRadio } from '../FormGroupRadio';
import React, { useCallback, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import { LevelObj } from '../../../../Types/FormFields';
import { useFormContext } from 'react-hook-form';

interface LevelBoxProps {
  name: string;
  radioOptions: string[] | LevelObj[];
  open: boolean;
  handleOpenRadio: (value: boolean) => void;
  handleSelectedLabel?: (value: string | undefined) => void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    radioGroupWrapperBorder: {
      position: 'relative',
      marginBottom: 12,
      marginTop: 14,
      '&::before': {
        content: "''",
        position: 'absolute',
        left: -24,
        width: 'calc(100% + 48px)',
        height: 'calc(100%)',
        borderRadius: 8,
        border: '2px solid #EFEFEF',
        [theme.breakpoints.down('sm')]: {
          backgroundColor: '#F8F8F8',
          border: 'unset',
        },
      },
    },
    radioGroupSpeechBubble: {
      position: 'relative',
      '&::before': {
        content: "''",
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '14px solid transparent',
        borderBottom: `14px solid #EFEFEF`,
        left: 0,
        top: -28,
        [theme.breakpoints.down('sm')]: {
          borderBottom: `14px solid #F8F8F8`,
        },
      },

      '&::after': {
        content: "''",
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '14px solid transparent',
        borderBottom: '14px solid #fff',
        left: 0,
        top: -24,
        [theme.breakpoints.down('sm')]: {
          borderBottom: `14px solid #F8F8F8`,
        },
      },
    },
    levelWrapper: {
      paddingBottom: 10,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    levelBtn: {
      textTransform: 'unset',
      padding: '0 5px',
      color: '#999',
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: 27,
    },
  };
});

export const LevelBox = (props: LevelBoxProps): JSX.Element => {
  const { radioOptions, name, open, handleOpenRadio, handleSelectedLabel } = props;

  const { getValues, watch } = useFormContext();

  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      getValues(name) !== '' && handleOpenRadio(false);
    }, 200);
  }, [watch(name)]);

  const getSelectedLevelLabel = useCallback(() => {
    const value = getValues(name);
    const isValueNum = +value;
    const radioOptionsObj = radioOptions as LevelObj[];
    let selectedLevelLabel = '';

    if (isValueNum) {
      const radioOption = radioOptionsObj.find((radioOpt: LevelObj) => radioOpt.id === value);
      radioOption && (selectedLevelLabel = radioOption.label);
    } else selectedLevelLabel = value;

    return selectedLevelLabel;
  }, [name, getValues, radioOptions, handleSelectedLabel]);

  useEffect(() => {
    if (
      getValues('filteredCheckboxes').filter(
        (checkbox: any) => checkbox.level === '' && checkbox.isChecked,
      ).length === 0 &&
      handleSelectedLabel &&
      getValues(name) !== ''
    ) {
      handleSelectedLabel(getValues(name));
    }
  }, [getValues(name)]);

  return (
    <>
      {open ? (
        <Box className={classes.radioGroupWrapperBorder}>
          <Box className={classes.radioGroupSpeechBubble}>
            <FormGroupRadio radioOptions={radioOptions} name={name} />
          </Box>
        </Box>
      ) : (
        <Box className={classes.levelWrapper}>
          <Button className={classes.levelBtn} onClick={() => handleOpenRadio(true)}>
            {getSelectedLevelLabel()}
          </Button>
        </Box>
      )}
    </>
  );
};
