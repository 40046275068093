import React, { useCallback } from 'react';
import { AppContext } from '../Context/Context';
import { StepsTypes } from '../Context/StepsReducer';

export function useFormSnackbar(): (value: boolean, message: string) => void {
  const { dispatch } = React.useContext(AppContext);
  return useCallback(
    (value: boolean, message: string) => {
      dispatch({
        type: StepsTypes.SET_FORM_SNACKBAR,
        payload: { formSnackbar: value, formSnackbarMessage: message },
      });
    },
    [dispatch],
  );
}
