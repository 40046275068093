import { Box, Button, List, ListItem, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckIcon from '../../../Icons/Check.svg';
import { StepsProgressBar } from '../StepsProgressBar';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';

export interface SidebarProps {
  step: number;
  handlePretendStep: (value: number) => void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      marginTop: 304,
    },
    list: {
      marginLeft: 24,
      marginTop: 24,
      padding: 0,
      '& li': {
        padding: 0,
        marginBottom: 8,
      },
      '& .MuiButtonBase-root ': {
        textTransform: 'none',
        margin: 0,
        padding: 0,
        color: theme.palette.primary.main,
        lineHeight: '24px',
        fontSize: 16,
      },
    },
    activeListItemText: {
      '&.MuiButtonBase-root': {
        color: '#000',
        fontWeight: 600,
      },
    },
    pastListItemText: {
      '&.MuiButtonBase-root': {
        color: '#B9A06A',
        fontWeight: 400,
      },
    },
    prefixListItemText: {
      display: 'inline-block',
      width: 24,
      marginRight: 12,
      textAlign: 'center',
      '& img': {
        height: 11,
        width: 14,
      },
    },
  };
});

export const Sidebar = (props: SidebarProps): JSX.Element => {
  const { handlePretendStep } = props;
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const {
    steps: { completedStep, step },
  } = state;

  return (
    <Box className={classes.root}>
      <StepsProgressBar step={step} />
      <List className={classes.list}>
        <ListItem>
          <Button
            className={clsx({
              [classes.activeListItemText]: step === 1,
              [classes.pastListItemText]: step > 1,
            })}
            onClick={() => handlePretendStep(1)}
            disabled={!(completedStep >= 1 || completedStep + 1 === 1)}>
            <span className={classes.prefixListItemText}>
              {step > 1 ? <img src={CheckIcon} /> : '1.'}
            </span>
            Fachexpertise
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className={clsx({
              [classes.activeListItemText]: step === 2,
              [classes.pastListItemText]: step > 2,
            })}
            onClick={() => handlePretendStep(2)}
            disabled={!(completedStep >= 2 || completedStep + 1 === 2)}>
            <span className={classes.prefixListItemText}>
              {step > 2 ? <img src={CheckIcon} /> : '2.'}
            </span>
            Tech-Stack
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className={clsx({
              [classes.activeListItemText]: step === 3,
              [classes.pastListItemText]: step > 3,
            })}
            onClick={() => handlePretendStep(3)}
            disabled={!(completedStep >= 3 || completedStep + 1 === 3)}>
            <span className={classes.prefixListItemText}>
              {step > 3 ? <img src={CheckIcon} /> : '3.'}
            </span>
            Branchenexpertise
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className={clsx({
              [classes.activeListItemText]: step === 4,
              [classes.pastListItemText]: step > 4,
            })}
            onClick={() => handlePretendStep(4)}
            disabled={!(completedStep >= 4 || completedStep + 1 === 4)}>
            <span className={classes.prefixListItemText}>
              {step > 4 ? <img src={CheckIcon} /> : '4.'}
            </span>
            Sprache
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className={clsx({
              [classes.activeListItemText]: step === 5,
              [classes.pastListItemText]: step > 5,
            })}
            onClick={() => handlePretendStep(5)}
            disabled={!(completedStep >= 5 || completedStep + 1 === 5)}>
            <span className={classes.prefixListItemText}>
              {step > 5 ? <img alt={''} src={CheckIcon} /> : '5.'}
            </span>
            Weitere Daten
          </Button>
        </ListItem>
        {/*<ListItem>*/}
        {/*  <Button*/}
        {/*    className={clsx({*/}
        {/*      [classes.activeListItemText]: step === 6,*/}
        {/*      [classes.pastListItemText]: step > 6,*/}
        {/*    })}*/}
        {/*    onClick={() => handlePretendStep(6)}*/}
        {/*    disabled={!(completedStep >= 6 || completedStep + 1 === 6)}>*/}
        {/*    <span className={classes.prefixListItemText}>*/}
        {/*      {step > 6 ? <img src={CheckIcon} /> : '6.'}*/}
        {/*    </span>*/}
        {/*    Branchenexpertise*/}
        {/*  </Button>*/}
        {/*</ListItem>*/}
        {/*<ListItem>*/}
        {/*  <Button*/}
        {/*    className={clsx({*/}
        {/*      [classes.activeListItemText]: step === 7,*/}
        {/*      [classes.pastListItemText]: step > 7,*/}
        {/*    })}*/}
        {/*    onClick={() => handlePretendStep(7)}*/}
        {/*    disabled={!(completedStep >= 7 || completedStep + 1 === 7)}>*/}
        {/*    <span className={classes.prefixListItemText}>*/}
        {/*      {step > 7 ? <img src={CheckIcon} /> : '7.'}*/}
        {/*    </span>*/}
        {/*    Dokumente*/}
        {/*  </Button>*/}
        {/*</ListItem>*/}
      </List>
    </Box>
  );
};
