import { Box, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// import SearchIcon from '../../../../Icons/Search.svg';
import SearchIcon from '../../../Icons/Search.svg';
import RemoveSearchIcon from '../../../Icons/Remove-search.svg';
import clsx from 'clsx';

import { createStyles, withStyles } from '@material-ui/core';

const SearchTextFieldStyles = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      color: '#000',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },

      '& input': {
        fontSize: 16,
        caretColor: theme.palette.secondary.main,
        padding: '14px 0',
        '&::placeholder': {
          color: theme.palette.primary.main,
          opacity: 1,
        },
      },

      '& .MuiInputBase-root': {
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },

      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
      },

      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: 12,
        '& .searchIcon': {
          paddingRight: '12px',
          cursor: 'pointer',
        },
      },

      '& .Mui-focused.MuiOutlinedInput-adornedStart .searchIcon': {
        filter:
          'invert(60%) sepia(95%) saturate(1671%) hue-rotate(165deg) brightness(97%) contrast(84%)',
      },

      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 15,
        '& .removeSearchIcon': {
          cursor: 'pointer',
        },
      },
    },
  }),
);

interface SearchProps {
  placeholder: string;
  searchValue?: string;
  filterHandleChange: (value: string) => void;
}

const useStyles = makeStyles<Theme>(() => {
  return {
    root: {
      marginBottom: 16,
    },
  };
});

export const SearchTextFieldStyled = SearchTextFieldStyles(TextField);

export const SearchFilter: React.FC<SearchProps> = ({
  placeholder,
  filterHandleChange,
  searchValue,
}) => {
  const [value, setValue] = useState('');
  const classes = useStyles();

  // console.log("searchValue || ''", searchValue || '');

  useEffect(() => {
    if (searchValue === undefined) {
      setValue('');
    } else {
      setValue(searchValue);
    }
  }, [searchValue]);

  const handleChange = (value: string) => {
    setValue(value);
    filterHandleChange(value);
  };

  return (
    <Box className={clsx(classes.root, 'searchTextField')}>
      <SearchTextFieldStyled
        color="secondary"
        onChange={e => {
          handleChange(e.currentTarget.value);
        }}
        value={value}
        placeholder={placeholder}
        variant="outlined"
        type="text"
        InputLabelProps={{ shrink: false }}
        InputProps={{
          startAdornment: <img src={SearchIcon} className={'searchIcon'} />,
          endAdornment: value.length > 0 && (
            <img
              src={RemoveSearchIcon}
              className={'removeSearchIcon'}
              onClick={() => {
                handleChange('');
              }}
            />
          ),
        }}
      />
    </Box>
  );
};
