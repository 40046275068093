import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import HelpIcon from '../../../Icons/Help.svg';
import CloseGreyIcon from '../../../Icons/Close-grey.svg';
import { Contact } from '../Contact/Contact';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      position: 'relative',
    },
    btnPopover: {
      color: theme.palette.secondary.main,
      textTransform: 'unset',
      padding: 0,
    },
    btnPopoverText: {
      marginRight: 4,
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
    },
    bubbleIcon: {
      width: 24,
      height: 24,
    },
    helpModal: {
      top: 0,
      right: 0,
      position: 'absolute',
      zIndex: 1,
      background: '#FFF',
      padding: 24,
      width: 400,
      borderRadius: 4,
      boxShadow: '0 8px 20px 0 rgba(0,0,0,0.12)',
      [theme.breakpoints.down('md')]: {
        width: 280,
      },
    },
    helpHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: '20px',
      marginBottom: 20,
    },
    closeIcon: {
      position: 'absolute',
      right: 12,
      top: 12,
      padding: 0,
    },
  };
});

export const Help = (): JSX.Element => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.root}>
        <Button className={classes.btnPopover} onClick={handleChange}>
          <Typography variant={'body2'} className={classes.btnPopoverText}>
            Fragen
          </Typography>
          <svg className={classes.bubbleIcon} width="24px" height="24px" viewBox="0 0 24 24">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1344.000000, 0.000000)">
                <g transform="translate(1288.000000, 0.000000)">
                  <g transform="translate(56.000000, 0.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M20,16 L6,16 L4,18 L4,4 L20,4 L20,16 Z"
                      fill="#32AFE4"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </Button>
        <Fade in={isOpen}>
          <Box className={classes.helpModal}>
            <Typography variant={'body2'} className={classes.helpHeader}>
              Bei Fragen helfen wir gerne
            </Typography>
            <IconButton className={classes.closeIcon} onClick={handleChange}>
              <img src={CloseGreyIcon} />
            </IconButton>
            <Contact />
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};
