import { Box, Button, DialogActions, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Theme } from '@material-ui/core/styles';
import { CheckboxGroup } from '../../CheckboxGroup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CheckboxListItemApi } from '../../../../../Types/ResponseType';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface CheckboxesContentProps {
  header: string;
  name: string;
  options: CheckboxListItemApi[];
  subHeader?: string;
  methods: any;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    formWrapper: {
      width: 400,
    },
    header: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: '32px',
      color: '#000',
      marginBottom: 24,
      fontFamily: 'Maven Pro Bold',
    },
    subHeader: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.primary.main,
      marginBottom: 36,
    },
    listWrapper: {
      overflowY: 'auto',
      marginBottom: 50,
      width: '100%',
    },
    boxWrapper: {
      width: '100%',
    },
  };
});

export const CheckboxesContent = (props: CheckboxesContentProps): JSX.Element => {
  const { name, options, header, subHeader, methods } = props;
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');

  return (
    <Grid container className={classes.formWrapper}>
      <FormProvider {...methods}>
        <Box className={classes.boxWrapper}>
          {!mobileView && (
            <Typography variant={'body2'} className={classes.header}>
              {header}
            </Typography>
          )}
          {subHeader && (
            <Typography variant={'body2'} className={classes.subHeader}>
              {subHeader}
            </Typography>
          )}

          <Grid item className={classes.listWrapper}>
            <CheckboxGroup name={name} options={options} />
          </Grid>
        </Box>
      </FormProvider>
    </Grid>
  );
};
