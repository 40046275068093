import { createStyles, withStyles } from '@material-ui/core';

export const SearchTextFieldStyles = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      color: '#000',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },

      '& input': {
        fontSize: 16,
        caretColor: theme.palette.secondary.main,
        padding: '14px 0',
        '&::placeholder': {
          color: theme.palette.primary.main,
          opacity: 1,
        },
      },

      '& .MuiInputBase-root': {
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },

      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
      },

      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: 12,
        '& .searchIcon': {
          paddingRight: '12px',
          cursor: 'pointer',
        },
      },

      '& .Mui-focused.MuiOutlinedInput-adornedStart .searchIcon': {
        filter: 'invert(60%) sepia(95%) saturate(1671%) hue-rotate(165deg) brightness(97%) contrast(84%)',
      },

      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 15,
        '& .removeSearchIcon': {
          cursor: 'pointer',
        },
      },
    },
  }),
);