import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Radio,
  Typography,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { IconAddItem } from '../../../Icons/IconAddItem';
import { IconCloseLarge } from '../../../Icons/IconCloseLarge';
import { Selection } from './Selection';
import { HandleUpdateParams } from '../Steps/TechStack';

interface AddItemProps {
  group: string;
  searchValue: string;
  defaultGroups: string[];
  addItem(value: HandleUpdateParams): void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    newListItem: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 12,
      color: theme.palette.secondary.main,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 24,
      width: '100%',
      '& p': {
        fontWeight: 600,
        textTransform: 'initial',
      },
    },
    dialog: {
      '& .MuiDialog-paper ': {
        width: 400,
      },
    },
    dialogTitle: {
      borderBottom: '1px solid #EFEFEF',
      padding: 20,
      '& h2': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& p': {
        color: '#000000',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
    dialogContent: {
      padding: 24,
      paddingTop: 0,
    },
    closeButton: {
      padding: 0,
      '& svg': {
        height: 24,
        width: 24,
      },
    },
  };
});

export const AddItem: React.FC<AddItemProps> = ({ group, searchValue, defaultGroups, addItem }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleAddItem = ({ parent, id, value }: HandleUpdateParams) => {
    handleClose();
    addItem({ parent, id, value });
  };

  const handleShowModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={classes.newListItem} onClick={handleShowModal}>
        <Typography variant="body2">{`„${searchValue}” hinzufügen`}</Typography>
        <IconAddItem />
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="body2">
            <strong>{`„${searchValue}”`}</strong> hinzufügen
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
            <IconCloseLarge />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Selection group={group} defaultGroup={defaultGroups} addItem={handleAddItem} />
        </DialogContent>
      </Dialog>
    </>
  );
};
