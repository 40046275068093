import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Mail from '../../../Icons/Mail-grey.svg';
import BlueMail from '../../../Icons/Mail–blue.svg';
import Phone from '../../../Icons/Phone-grey.svg';
import BluePhone from '../../../Icons/Phone-blue.svg';
import { ContactLeftPartStyles } from './ContactLeftPartStyles';
import { ContactForBubbleStyles } from './ContactForBubbleStyles';

interface ContactProps {
  context?: any;
}

export const Contact = (props: ContactProps): JSX.Element => {
  const { context } = props;
  const classes = context === 'grayView' ? ContactLeftPartStyles() : ContactForBubbleStyles();

  //tp mne kest greu voew
  return (
    <Grid className={classes.root}>
      <Grid className={clsx(classes.emailWrapper, classes.wrapperInfo)}>
        {context === 'grayView' ? (
          <svg width="24px" height="24px" viewBox="0 0 24 24" className={classes.icon}>
            <g id="Prozess" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="0.-Start" transform="translate(-149.000000, -846.000000)">
                <g id="Icon" transform="translate(149.000000, 846.000000)">
                  <path
                    d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z"
                    id="Shape"
                    fill="#999999"
                    fillRule="nonzero"></path>
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                </g>
              </g>
            </g>
          </svg>
        ) : (
          <svg width="24px" height="24px" viewBox="0 0 24 24" className={classes.icon}>
            <g id="Prozess" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="1.-Kontakt---Fragen" transform="translate(-1028.000000, -100.000000)">
                <g id="Fragen" transform="translate(1004.000000, 36.000000)">
                  <g id="Group-2" transform="translate(24.000000, 64.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z"
                      id="Shape"
                      fill="#32AFE4"
                      fillRule="nonzero"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )}
        {/*<img src={context === 'grayView' ? Mail : BlueMail} alt={''} className={classes.icon} />*/}
        <Typography variant={'body2'} className={classes.contactText}>
          freelancer@bizforward.de
        </Typography>
      </Grid>
      <Grid className={classes.wrapperInfo}>
        {context === 'grayView' ? (
          <svg width="24px" height="24px" viewBox="0 0 24 24" className={classes.icon}>
            <title>Icon</title>
            <g id="Prozess" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="0.-Start" transform="translate(-392.000000, -846.000000)">
                <g id="Kontakt" transform="translate(149.000000, 846.000000)">
                  <g id="Icon" transform="translate(243.000000, 0.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.62,10.79 C8.06,13.62 10.38,15.93 13.21,17.38 L15.41,15.18 C15.68,14.91 16.08,14.82 16.43,14.94 C17.55,15.31 18.76,15.51 20,15.51 C20.55,15.51 21,15.96 21,16.51 L21,20 C21,20.55 20.55,21 20,21 C10.61,21 3,13.39 3,4 C3,3.45 3.45,3 4,3 L7.5,3 C8.05,3 8.5,3.45 8.5,4 C8.5,5.25 8.7,6.45 9.07,7.57 C9.18,7.92 9.1,8.31 8.82,8.59 L6.62,10.79 Z"
                      id="Path"
                      fill="#999999"
                      fillRule="nonzero"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        ) : (
          <svg width="24px" height="24px" viewBox="0 0 24 24" className={classes.icon}>
            <title>Group 3</title>
            <g id="Prozess" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="1.-Kontakt---Fragen" transform="translate(-1028.000000, -132.000000)">
                <g id="Fragen" transform="translate(1004.000000, 36.000000)">
                  <g id="Group-3" transform="translate(24.000000, 96.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M6.62,10.79 C8.06,13.62 10.38,15.93 13.21,17.38 L15.41,15.18 C15.68,14.91 16.08,14.82 16.43,14.94 C17.55,15.31 18.76,15.51 20,15.51 C20.55,15.51 21,15.96 21,16.51 L21,20 C21,20.55 20.55,21 20,21 C10.61,21 3,13.39 3,4 C3,3.45 3.45,3 4,3 L7.5,3 C8.05,3 8.5,3.45 8.5,4 C8.5,5.25 8.7,6.45 9.07,7.57 C9.18,7.92 9.1,8.31 8.82,8.59 L6.62,10.79 Z"
                      id="Path"
                      fill="#32AFE4"
                      fillRule="nonzero"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )}
        {/*<img src={context === 'grayView' ? Phone : BluePhone} alt={''} className={classes.icon} />*/}
        <Typography variant={'body2'} className={classes.contactText}>
          +49 2151 780 79 50
        </Typography>
      </Grid>
    </Grid>
  );
};
