import React, { createContext, Dispatch, useReducer } from 'react';
import { StepsAction, StepsReducer, StepsState } from './StepsReducer';
import { shortRegInitialValues } from '../Domain/components/Steps/ShortRegistration/initialValues';
import { competenceInitialValues } from '../Domain/components/Steps/Competences/initialValues';
import { detailsInitialValues } from '../Domain/components/Steps/Details/initialValues';
import { industryKnowledgeInitialValues } from '../Domain/components/Steps/IndustryKnowledge/initialValues';
import { languageInitialValues } from '../Domain/components/Steps/Languages/initialValues';
import { techStackInitialValues } from '../Domain/components/Steps/TechStack/initialValues';

export interface InitialState {
  steps: StepsState;
}

export interface AppProviderProps {
  children: JSX.Element;
}

// const initialState: InitialState = {
//   steps: {
//     step: 1,
//     completedStep: 0,
//     pretendStep: 0,
//     fields: {
//       firstName: '',
//       surname: '',
//       gender: '',
//       email: '',
//       password: '',
//       passwordConfirmation: '',
//       areaCode: '+49',
//       phone: '',
//       website: '',
//       postCode: '',
//       residence: '',
//       country: 200,
//       regions: [],
//       languages: [],
//       competences: [],
//       techsGroupsOpt: [],
//       sectors: [],
//       uploadedProfile: null,
//       uploadedGenDocs: [],
//       comment: '',
//       available_from: '',
//       days_per_week: '',
//       daily_rate: '',
//       contract_staffing: false,
//       newsletter: false,
//       linkedin_url: '',
//       xing_url: '',
//       insurance: false,
//       pro_bono: false,
//       profileUrl: '',
//       isGdpr: false,
//       isFreelancer: false,
//     },
//     shortReg: {
//       firstName: '',
//       surname: '',
//       email: '',
//       profileUrl: '',
//       uploadedGenDocs: [],
//       password: '',
//       passwordConfirmation: '',
//       isGdpr: false,
//       isFreelancer: false,
//     },
//     regData: null,
//     formSnackbar: false,
//     formSnackbarMessage: '',
//   },
// };

const initialState: InitialState = {
  steps: {
    step: 1,
    completedStep: 0,
    pretendStep: 0,
    fields: {
      ...competenceInitialValues,
      ...detailsInitialValues,
      ...industryKnowledgeInitialValues,
      ...languageInitialValues,
      ...techStackInitialValues,
    },
    shortReg: shortRegInitialValues,
    regData: null,
    formSnackbar: false,
    formSnackbarMessage: '',
    signupId: '',
  },
};

export const MainReducer = ({ steps }: InitialState, action: any) => ({
  steps: StepsReducer(steps, action),
});

export const AppContext = createContext<{
  state: InitialState;
  dispatch: Dispatch<StepsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(MainReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
