import { Controller, useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { FormCheckboxStyles } from './Styles/FormCheckboxStyles';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import { CustomTooltip } from './Tooltip/CustomTooltip';
import Info from '../../../Icons/Info.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface FormCheckboxProps {
  name: string;
  label: string | React.ReactNode;
  tooltipTitle?: string;
}

export const FormCheckboxStyled = FormCheckboxStyles(Checkbox);

const useStyles = makeStyles<Theme>(theme => {
  return {
    label: {
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '8px 0',
      margin: 0,
      alignItems: 'flex-start',
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },
      '& .tooltipIcon': {
        cursor: 'pointer',
        marginLeft: 8,
        verticalAlign: 'text-bottom',
      },
    },
    errorLabel: {
      '& .MuiFormControlLabel-label': {
        color: theme.palette.error.main,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.error.main,
      },
    },
  };
});

export const FormCheckbox = (props: FormCheckboxProps) => {
  const { name, label, tooltipTitle } = props;
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const mobileView = useMediaQuery('(max-width:1024px)');

  const handleChecked = useCallback(
    (value: any, name: any) => {
      setValue(name, !value);
    },
    [setValue],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name }, fieldState: { invalid } }) => {
        return (
          <FormControlLabel
            className={clsx(classes.label, { [classes.errorLabel]: invalid })}
            control={
              <FormCheckboxStyled
                color={'secondary'}
                checked={value}
                onChange={() => handleChecked(value, name)}
              />
            }
            label={
              <>
                {label}
                {tooltipTitle && (
                  <CustomTooltip
                    onClick={e => e.preventDefault()}
                    leaveTouchDelay={2500}
                    enterTouchDelay={1}
                    title={tooltipTitle}
                    arrow={!mobileView}
                    placement={mobileView ? 'bottom-end' : 'right'}>
                    <img alt={''} src={Info} className={'tooltipIcon'} />
                  </CustomTooltip>
                )}
              </>
            }
          />
        );
      }}
    />
  );
};
