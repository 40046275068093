import React, { useState } from 'react';
import clsx from 'clsx';
import { FormCheckboxStyled } from '../../../App/Shared/Form/FormCheckbox';
import { Box, FormControlLabel, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { SelectionRadio } from './SelectionRadio';
import { HandleUpdateParams } from '../Steps/TechStack';

const useStyles = makeStyles<Theme>(theme => {
  return {
    label: {
      display: 'block',
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '10px 0',
      margin: 0,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },
    },
    levelWrapper: {
      paddingBottom: 10,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    levelBtn: {
      textTransform: 'unset',
      padding: '0 5px',
      color: '#999',
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: 27,
    },
    labelOpenRadio: {
      paddingBottom: 0,
    },
    labelChecked: {
      fontWeight: 500,
      fontSize: 16,
      color: '#000',
      '& span': {
        fontWeight: 600,
      },
    },
    labelCheckedLevel: {
      '&.MuiFormControlLabel-root': {
        borderBottom: 'unset !important',
      },
    },
  };
});

interface CheckBoxWithSelectionProps {
  parent: string;
  label: string;
  id: string;
  checked: boolean;
  value?: string;
  onUpdate: (values: HandleUpdateParams) => void;
}

export const CheckBoxWithSelection: React.FC<CheckBoxWithSelectionProps> = ({
  parent,
  id,
  label,
  checked,
  value,
  onUpdate,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const updateValue = (value: string | null) => {
    onUpdate({
      parent,
      id,
      value,
    });
  };

  const handleCheckbox = () => {
    if (checked) {
      updateValue(null);
      setIsOpen(false);
      return;
    }

    setIsOpen(!isOpen);
  };

  const handleSelectionUpdate = (value: string) => {
    updateValue(value);
    setIsOpen(false);
  };

  return (
    <>
      <FormControlLabel
        className={clsx(classes.label, {
          [classes.labelChecked]: checked,
          [classes.labelCheckedLevel]: checked,
          [classes.labelOpenRadio]: !isOpen && checked,
        })}
        control={
          <FormCheckboxStyled
            color="secondary"
            checked={checked || isOpen}
            onChange={handleCheckbox}
          />
        }
        label={label}
      />
      {isOpen && <SelectionRadio handleUpdate={handleSelectionUpdate} />}
      {!isOpen && checked && (
        <Box className={classes.levelWrapper}>
          <span className={classes.levelBtn}>{value}</span>
        </Box>
      )}
    </>
  );
};
