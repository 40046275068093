import * as Yup from 'yup';

export const detailsSchema = Yup.object().shape({
  days_per_week: Yup.number()
    .min(1)
    .max(5)
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  daily_rate: Yup.number()
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  contract_staffing: Yup.boolean(),
  newsletter: Yup.boolean(),
  insurance: Yup.boolean(),
  pro_bono: Yup.boolean(),
  areaCode: Yup.string().max(3),
  phone: Yup.string(),
  // regions: Yup.array(),
  postCode: Yup.number()
    .min(5)
    .max(5)
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  residence: Yup.string(),
  comment: Yup.string(),
  // available_from: Yup.string().test(`available_fromTest`, '', (data: any) => {
  //   const today = new Date().getTime();
  //   let date = data.split('.');
  //
  //   date = new Date(date[2], date[1] - 1, date[0]).getTime();
  //   return today - date < 0;
  // }),
});
