import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import LogoIconWhite from '../../../Icons/bizforward_logo_white.svg';
import LogoIconGray from '../../../Icons/bizforward_logo_gray.svg';

interface LogoProps {
  isLogoGray?: boolean;
}

const useStyles = makeStyles<Theme>(() => {
  return {
    logo: {
      width: 200,
      height: 36,
    },
    logoGray: {},
  };
});

export const Logo = (props: LogoProps): JSX.Element => {
  const { isLogoGray } = props;
  const classes = useStyles();

  return isLogoGray ? (
    <svg className={classes.logo}>
      <g>
        <rect fill="#F8F8F8" width="1611.3" height="296.7" />
      </g>
      <g>
        <path
          fill="#575656"
          d="M327.2,55.7v45.1h44c3.7,0,7.8,0.3,12.2,0.8c4.4,0.5,8.8,1.6,13.1,3.2c4.3,1.6,8.5,3.9,12.5,6.9
		c4,3,7.5,7.1,10.5,12.2c2.3,3.7,4.1,7.7,5.3,11.9c1.2,4.2,2.2,8.5,2.8,12.7c0.6,4.2,1,8.4,1.2,12.6c0.2,4.2,0.3,8.1,0.3,11.8
		c0,3.2-0.1,6.6-0.3,10.3c-0.2,3.7-0.6,7.6-1.2,11.5c-0.6,4-1.5,8-2.8,11.9c-1.2,4-2.9,7.8-5,11.5c-3,5.1-6.5,9.2-10.5,12.2
		c-4,3-8.1,5.3-12.3,6.9c-4.2,1.6-8.5,2.7-12.7,3.2c-4.2,0.5-8.3,0.8-12.2,0.8h-30.2c-4.1,0-8.1-0.2-12.1-0.7
		c-4-0.4-7.2-1.6-9.7-3.6c-3-2.5-4.9-5.4-5.6-8.9c-0.7-3.4-1.1-6.4-1.1-8.9V55.7H327.2z M327.2,218c0,1.4,0,2.9,0.1,4.5
		c0.1,1.6,0.6,2.9,1.5,4c0.9,1.1,2.2,1.7,3.8,2c1.7,0.3,3.1,0.4,4.4,0.4h32.1c2.7,0,5.7-0.1,9.3-0.4c3.5-0.3,7.1-1,10.7-2.3
		c3.6-1.2,7.1-3.1,10.5-5.6c3.4-2.5,6.3-5.9,8.8-10.3c2.5-4.4,4.1-9.4,4.8-15c0.7-5.6,1.1-11.7,1.1-18.4c0-3.4-0.1-7.1-0.3-11.1
		c-0.2-4.1-0.5-8.1-0.9-12.1c-0.4-4-1-7.7-1.7-11.3c-0.7-3.5-1.6-6.5-2.6-9c-2-4.4-4.5-8-7.6-10.7c-3.1-2.7-6.4-4.8-9.8-6.1
		c-3.5-1.3-7-2.2-10.7-2.7c-3.7-0.4-7.2-0.7-10.3-0.7h-43V218z"
        />
        <path
          fill="#575656"
          d="M461.2,75.3V55.7H476v19.6H461.2z M461.7,100.8h13.8v140.6h-13.8V100.8z"
        />
        <path
          fill="#575656"
          d="M517.3,240.9c-2-0.3-3.9-0.8-5.6-1.6c-1.7-0.8-3.1-2-4.4-3.6c-1.2-1.6-1.9-3.7-1.9-6.4c0-3,0.8-5.8,2.4-8.4
		c1.6-2.6,3.1-4.6,4.5-6.2l81.2-95.2c0.5-0.7,1.1-1.3,1.7-1.9c0.6-0.5,0.9-1.2,0.9-2.1c0-1.1-0.3-1.7-0.9-2
		c-0.6-0.3-1.4-0.4-2.3-0.4h-83.6v-12.5h89.9c3.9,0,6.8,1.2,8.7,3.7c2,2.5,2.9,5,2.9,7.7c0,1.6-0.4,3.5-1.2,5.8
		c-0.8,2.3-1.7,4.2-2.8,5.6l-84.6,100.3c-0.4,0.5-0.8,1.1-1.3,1.6c-0.5,0.5-0.8,1.2-0.8,2.1c0,0.9,0.3,1.3,0.8,1.3
		c0.5,0,1.1,0,1.6,0h89.1v12.5h-89.1C521.1,241.3,519.3,241.2,517.3,240.9z"
        />
        <path
          fill="#3DAFE4"
          d="M649,100.8c0-2.3,0.1-4.9,0.4-8c0.3-3,0.8-6.1,1.6-9.2c0.8-3.1,1.9-6.1,3.3-9c1.4-2.9,3.4-5.6,5.8-8.1
		c2.7-2.6,5.7-4.8,9-6.4c3.3-1.6,6.9-2.9,10.6-3.8c3.7-1,7.4-1.6,11-1.9c3.6-0.3,7-0.4,10.2-0.4v12.5c-4.4,0-9.5,0.4-15.3,1.3
		c-5.7,0.9-10.4,2.8-13.9,5.8c-1.8,1.6-3.2,3.4-4.4,5.6c-1.2,2.1-2.1,4.4-2.8,6.9c-0.7,2.5-1.2,5-1.5,7.6c-0.3,2.6-0.4,4.9-0.4,7
		h37.9v12.5h-37.9v128.1H649V113.2h-18.3v-12.5H649z"
        />
        <path
          fill="#3DAFE4"
          d="M717.7,157.1c0.4-4.9,1.1-9.8,2.4-14.9c1.2-5,3-10,5.3-14.9c2.3-4.9,5.4-9.1,9.3-12.9
		c3.4-3.4,7.2-6.1,11.4-8.1c4.2-2,8.6-3.6,13.1-4.6c4.5-1.1,9.1-1.8,13.8-2.1c4.7-0.4,9.2-0.5,13.7-0.5c4.4,0,9,0.2,13.7,0.5
		c4.7,0.4,9.3,1.1,13.8,2.1c4.5,1.1,8.8,2.6,13,4.6c4.2,2,8,4.7,11.5,8.1c3.9,3.7,7,8,9.3,12.9c2.3,4.9,4.1,9.8,5.3,14.9
		c1.2,5,2,10,2.4,14.9c0.4,4.9,0.5,9.2,0.5,13.1c0,15.2-1.8,27.4-5.4,36.5c-3.6,9.1-7.7,15.9-12.1,20.3c-3.9,3.7-8.2,6.6-12.9,8.8
		c-4.7,2.1-9.4,3.7-14.2,4.8c-4.8,1.1-9.3,1.7-13.7,2c-4.3,0.3-8.1,0.4-11.3,0.4c-3.2,0-6.9-0.1-11.3-0.4c-4.3-0.3-8.9-0.9-13.7-2
		c-4.8-1.1-9.5-2.7-14.2-4.8c-4.7-2.1-9-5-12.9-8.8c-4.6-4.4-8.7-11.2-12.2-20.3c-3.5-9.1-5.3-21.3-5.3-36.5
		C717.2,166.4,717.4,162,717.7,157.1z M732.2,181.7c0.1,3.9,0.5,7.9,1.2,12.1c0.7,4.2,1.8,8.3,3.3,12.3c1.5,4.1,3.8,7.7,6.8,10.9
		c2.8,3,6,5.4,9.4,7.3c3.4,1.9,7.1,3.2,11,4.1c3.9,0.9,7.8,1.5,11.7,1.7c3.9,0.3,7.6,0.4,11.1,0.4c3.5,0,7.3-0.1,11.1-0.4
		c3.9-0.3,7.8-0.8,11.7-1.7c3.9-0.9,7.6-2.3,11-4.1c3.4-1.9,6.6-4.3,9.4-7.3c2.8-3.2,5-6.8,6.6-10.9c1.6-4.1,2.7-8.2,3.4-12.3
		c0.7-4.1,1.1-8.2,1.2-12.1c0.1-3.9,0.1-7.2,0.1-10.1c0-3-0.1-6.6-0.1-10.7c-0.1-4.2-0.5-8.4-1.2-12.7c-0.7-4.3-1.8-8.5-3.3-12.6
		c-1.5-4.1-3.7-7.7-6.5-10.9c-2.7-3-5.8-5.3-9.4-7c-3.6-1.7-7.4-3-11.4-3.8c-4-0.9-7.9-1.5-11.8-1.7c-3.9-0.3-7.5-0.4-10.9-0.4
		c-3.4,0-7,0.1-10.9,0.4c-3.9,0.3-7.8,0.8-11.8,1.7c-4,0.9-7.8,2.2-11.4,3.8c-3.6,1.7-6.8,4-9.4,7c-2.8,3.2-5,6.8-6.5,10.9
		c-1.5,4.1-2.6,8.3-3.3,12.6c-0.7,4.3-1.1,8.6-1.2,12.7c-0.1,4.2-0.1,7.7-0.1,10.7C732,174.4,732.1,177.8,732.2,181.7z"
        />
        <path
          fill="#3DAFE4"
          d="M881.6,149c0-7.2,0.7-13.9,2.3-20c1.5-6.1,4.9-11.7,10.2-16.8c3-2.8,7.1-5.4,12.3-7.8
		c5.2-2.4,12.6-3.6,22.1-3.6h9.5v12.5h-8.5c-3.9,0-8.4,0.5-13.5,1.6c-5.1,1.1-9.6,3.8-13.5,8.2c-3,3.5-5,7.9-5.8,13
		c-0.9,5.1-1.3,10.5-1.3,16.2v89.1h-13.8V149z"
        />
        <path
          fill="#3DAFE4"
          d="M995.7,224.9c0.3,0.9,0.7,1.8,1.2,2.8c0.4,1,1,1.5,1.7,1.5c0.9,0,1.5-0.6,1.9-1.7c0.4-1.1,0.6-1.9,0.8-2.3
		l30-114.3c1.4-5,3.4-8.1,5.8-9.5c2.5-1.4,4.8-2.1,6.9-2.1c2.1,0,4.4,0.7,6.9,2.1c2.5,1.4,4.4,4.6,5.8,9.5l30,114.3
		c0.2,0.4,0.4,1.1,0.8,2.3c0.4,1.2,1,1.7,1.9,1.7c0.7,0,1.3-0.5,1.7-1.5c0.4-1,0.8-1.9,1.2-2.8l31.6-124.1h13.8l-34.5,130.5
		c-0.9,3-2.3,5.7-4.1,8.1c-1.9,2.4-5,3.6-9.4,3.6c-2.7,0-4.8-0.4-6.5-1.3c-1.7-0.9-3-1.9-4.1-3.2c-1.1-1.2-1.9-2.5-2.5-3.8
		c-0.6-1.3-1-2.5-1.2-3.6l-29.2-109.8c-0.2-0.9-0.4-1.9-0.8-3c-0.4-1.1-0.8-1.7-1.3-1.7c-0.5,0-1,0.6-1.3,1.7
		c-0.4,1.2-0.6,2.2-0.8,3L1012.7,231c-0.4,1.1-0.8,2.3-1.3,3.6c-0.5,1.3-1.3,2.6-2.4,3.8c-1.1,1.2-2.4,2.3-4.1,3.2
		c-1.7,0.9-3.9,1.3-6.5,1.3c-4.4,0-7.6-1.2-9.4-3.6c-1.9-2.4-3.2-5.1-4.1-8.1l-34.5-130.5h13.8L995.7,224.9z"
        />
        <path
          fill="#3DAFE4"
          d="M1233.4,100.8c4.8,0,9.4,0.9,13.8,2.7c4.4,1.8,8,4.2,10.9,7.2c3.7,4.1,6.1,8.7,7,13.9
		c1,5.2,1.5,9.9,1.5,14.2v84.1c0,2-0.2,4.3-0.7,7.2c-0.4,2.8-1.8,5.3-4.1,7.4c-2.1,2-4.7,3.1-7.7,3.4c-3,0.4-5.7,0.5-8.2,0.5h-54.1
		c-4.1,0-8.4-0.3-12.9-0.9c-4.5-0.6-8.7-2.4-12.6-5.4c-3-2.3-5.3-5.2-6.9-8.6c-1.6-3.4-2.8-6.9-3.7-10.3c-0.9-3.4-1.4-6.8-1.6-10.1
		c-0.2-3.3-0.3-6-0.3-8.1c0-5.7,0.7-11.5,2-17.6c1.3-6.1,4.6-11.3,9.7-15.5c3.9-3.2,8.2-5.2,13-6.1c4.8-0.9,9.2-1.3,13.3-1.3h61v-21
		c0-2.3-0.4-5.1-1.3-8.5c-0.9-3.4-2.2-6.1-4-8.2c-1.4-1.8-3.4-3.3-6-4.5c-2.6-1.2-5.5-1.9-8.9-1.9h-68.2v-12.5H1233.4z
		 M1188.6,169.7c-4.1,0-7.4,0.8-9.9,2.4c-2.6,1.6-4.4,3.1-5.4,4.5c-1.8,2.3-3,5.3-3.6,8.9c-0.6,3.6-0.9,7.9-0.9,12.9
		c0,4.2,0.3,8.6,0.9,13.1c0.6,4.5,2.2,8.3,4.6,11.3c1.4,1.8,3.3,3.2,5.7,4.4c2.4,1.2,5.1,1.7,8.1,1.7h56.8c0.9,0,1.9,0,3.1-0.1
		c1.1-0.1,2.2-0.6,3-1.5c0.9-0.9,1.4-1.7,1.6-2.4c0.2-0.7,0.3-1.5,0.3-2.4v-52.8H1188.6z"
        />
        <path
          fill="#3DAFE4"
          d="M1304,149c0-7.2,0.7-13.9,2.2-20c1.5-6.1,4.9-11.7,10.2-16.8c3-2.8,7.1-5.4,12.3-7.8
		c5.2-2.4,12.6-3.6,22.1-3.6h9.6v12.5h-8.5c-3.9,0-8.4,0.5-13.5,1.6c-5.1,1.1-9.6,3.8-13.5,8.2c-3,3.5-5,7.9-5.8,13
		c-0.9,5.1-1.3,10.5-1.3,16.2v89.1H1304V149z"
        />
        <path
          fill="#3DAFE4"
          d="M1485.5,229.3c-0.5,2.9-1.5,5.3-2.9,7c-1.9,2.5-4.4,3.9-7.4,4.4c-3,0.4-5.7,0.7-8,0.7h-43.8
		c-3.5,0-7.4-0.3-11.5-0.9c-4.2-0.6-8.4-1.9-12.6-3.8c-4.2-1.9-8.2-4.8-11.9-8.5c-3.7-3.7-6.9-8.8-9.5-15.1
		c-2.8-6.7-4.6-14.1-5.3-22c-0.7-8-1.1-15.8-1.1-23.6c0-7.8,0.5-15.5,1.6-23.2c1.1-7.7,4.1-15.2,9-22.4c3.2-4.6,6.7-8.3,10.6-11
		c3.9-2.7,8-4.9,12.2-6.4c4.2-1.5,8.5-2.5,12.7-2.9c4.2-0.4,8.3-0.7,12.2-0.7h42.7V55.7h13.8v165.2
		C1486.2,223.6,1486,226.3,1485.5,229.3z M1426.6,113.2c-2.6,0-5.5,0.2-8.5,0.5c-3,0.4-6,1.1-9,2.3c-3,1.2-5.9,2.8-8.6,4.9
		c-2.7,2.1-5.2,4.9-7.3,8.2c-2.5,4.1-4.2,9.3-5.3,15.6c-1.1,6.4-1.6,14.6-1.6,24.7c0,6.4,0.1,11.7,0.4,16c0.3,4.3,0.6,8,1.1,10.9
		c0.4,2.9,0.9,5.2,1.3,6.9c0.4,1.7,0.8,3,1.2,3.8c1.8,4.8,4.2,8.6,7.2,11.5c3,2.9,6.2,5.1,9.6,6.6c3.4,1.5,6.8,2.5,10.2,2.9
		c3.5,0.4,6.6,0.7,9.4,0.7h35.3c0.9,0,2.2-0.1,4.1-0.3c1.9-0.2,3.3-0.8,4.4-1.9c0.9-0.9,1.5-2.4,1.7-4.5c0.3-2.1,0.4-3.9,0.4-5.3
		V113.2H1426.6z"
        />
      </g>
      <g id="Icon">
        <polygon fill="#54BEED" points="191,242.4 120.2,148.2 191,54.1 261.7,148.2 	" />
        <polygon fill="#3DAFE4" points="120.2,148.3 50,54.1 261.7,148.3 	" />
        <polygon fill="#77CDF4" points="191,54.1 50,54.1 155.7,101.1 	" />
        <polygon fill="#77CDF4" points="50,242.4 191,242.4 155.7,195.4 	" />
        <polygon fill="#0C96D4" points="120.2,148.2 50,242.4 261.7,148.2 	" />
      </g>
      <g id="R">
        <path
          fill="#3DAFE4"
          d="M1511.2,75c0-6.9,2.4-12.8,7.3-17.7c4.9-4.9,10.8-7.3,17.7-7.3c6.9,0,12.8,2.4,17.7,7.3
		c4.9,4.9,7.3,10.8,7.3,17.7c0,6.9-2.4,12.8-7.3,17.7c-4.9,4.9-10.8,7.3-17.7,7.3c-6.9,0-12.8-2.4-17.7-7.3
		C1513.7,87.9,1511.2,82,1511.2,75z M1514.4,75c0,6.1,2.1,11.2,6.4,15.4c4.3,4.2,9.4,6.4,15.5,6.4c6,0,11.1-2.1,15.4-6.4
		c4.2-4.2,6.4-9.4,6.4-15.4c0-6-2.1-11.1-6.4-15.4c-4.2-4.2-9.4-6.4-15.4-6.4c-6.1,0-11.2,2.1-15.5,6.4
		C1516.6,63.9,1514.4,69,1514.4,75z M1526.6,89.3V60.8h10.5c3.2,0,5.7,0.7,7.5,2.2c1.8,1.5,2.8,3.6,2.8,6.4c0,2.3-0.6,4.2-1.8,5.6
		c-1.2,1.4-2.7,2.3-4.5,2.8l7.5,11.5h-4.7l-6.9-10.8h-6.4v10.8H1526.6z M1530.6,74.7h6.4c4.3,0,6.4-1.7,6.4-5.2
		c0-3.5-2.2-5.2-6.6-5.2h-6.2V74.7z"
        />
      </g>
    </svg>
  ) : (
    <svg x="0px" y="0px" viewBox="0 0 1611.3 296.7" className={classes.logo}>
      <g id="BackGround">
        <rect fill="#FFFFFF" width="1611.3" height="296.7" />
      </g>
      <g id="BizForward">
        <path
          fill="#575656"
          d="M327.2,55.7v45.1h44c3.7,0,7.8,0.3,12.2,0.8c4.4,0.5,8.8,1.6,13.1,3.2c4.3,1.6,8.5,3.9,12.5,6.9
		c4,3,7.5,7.1,10.5,12.2c2.3,3.7,4.1,7.7,5.3,11.9c1.2,4.2,2.2,8.5,2.8,12.7c0.6,4.2,1,8.4,1.2,12.6c0.2,4.2,0.3,8.1,0.3,11.8
		c0,3.2-0.1,6.6-0.3,10.3c-0.2,3.7-0.6,7.6-1.2,11.5c-0.6,4-1.5,8-2.8,11.9c-1.2,4-2.9,7.8-5,11.5c-3,5.1-6.5,9.2-10.5,12.2
		c-4,3-8.1,5.3-12.3,6.9c-4.2,1.6-8.5,2.7-12.7,3.2c-4.2,0.5-8.3,0.8-12.2,0.8h-30.2c-4.1,0-8.1-0.2-12.1-0.7
		c-4-0.4-7.2-1.6-9.7-3.6c-3-2.5-4.9-5.4-5.6-8.9c-0.7-3.4-1.1-6.4-1.1-8.9V55.7H327.2z M327.2,218c0,1.4,0,2.9,0.1,4.5
		c0.1,1.6,0.6,2.9,1.5,4c0.9,1.1,2.2,1.7,3.8,2c1.7,0.3,3.1,0.4,4.4,0.4h32.1c2.7,0,5.7-0.1,9.3-0.4c3.5-0.3,7.1-1,10.7-2.3
		c3.6-1.2,7.1-3.1,10.5-5.6c3.4-2.5,6.3-5.9,8.8-10.3c2.5-4.4,4.1-9.4,4.8-15c0.7-5.6,1.1-11.7,1.1-18.4c0-3.4-0.1-7.1-0.3-11.1
		c-0.2-4.1-0.5-8.1-0.9-12.1c-0.4-4-1-7.7-1.7-11.3c-0.7-3.5-1.6-6.5-2.6-9c-2-4.4-4.5-8-7.6-10.7c-3.1-2.7-6.4-4.8-9.8-6.1
		c-3.5-1.3-7-2.2-10.7-2.7c-3.7-0.4-7.2-0.7-10.3-0.7h-43V218z"
        />
        <path
          fill="#575656"
          d="M461.2,75.3V55.7H476v19.6H461.2z M461.7,100.8h13.8v140.6h-13.8V100.8z"
        />
        <path
          fill="#575656"
          d="M517.3,240.9c-2-0.3-3.9-0.8-5.6-1.6c-1.7-0.8-3.1-2-4.4-3.6c-1.2-1.6-1.9-3.7-1.9-6.4c0-3,0.8-5.8,2.4-8.4
		c1.6-2.6,3.1-4.6,4.5-6.2l81.2-95.2c0.5-0.7,1.1-1.3,1.7-1.9c0.6-0.5,0.9-1.2,0.9-2.1c0-1.1-0.3-1.7-0.9-2
		c-0.6-0.3-1.4-0.4-2.3-0.4h-83.6v-12.5h89.9c3.9,0,6.8,1.2,8.7,3.7c2,2.5,2.9,5,2.9,7.7c0,1.6-0.4,3.5-1.2,5.8
		c-0.8,2.3-1.7,4.2-2.8,5.6l-84.6,100.3c-0.4,0.5-0.8,1.1-1.3,1.6c-0.5,0.5-0.8,1.2-0.8,2.1c0,0.9,0.3,1.3,0.8,1.3
		c0.5,0,1.1,0,1.6,0h89.1v12.5h-89.1C521.1,241.3,519.3,241.2,517.3,240.9z"
        />
        <path
          fill="#3DAFE4"
          d="M649,100.8c0-2.3,0.1-4.9,0.4-8c0.3-3,0.8-6.1,1.6-9.2c0.8-3.1,1.9-6.1,3.3-9c1.4-2.9,3.4-5.6,5.8-8.1
		c2.7-2.6,5.7-4.8,9-6.4c3.3-1.6,6.9-2.9,10.6-3.8c3.7-1,7.4-1.6,11-1.9c3.6-0.3,7-0.4,10.2-0.4v12.5c-4.4,0-9.5,0.4-15.3,1.3
		c-5.7,0.9-10.4,2.8-13.9,5.8c-1.8,1.6-3.2,3.4-4.4,5.6c-1.2,2.1-2.1,4.4-2.8,6.9c-0.7,2.5-1.2,5-1.5,7.6c-0.3,2.6-0.4,4.9-0.4,7
		h37.9v12.5h-37.9v128.1H649V113.2h-18.3v-12.5H649z"
        />
        <path
          fill="#3DAFE4"
          d="M717.7,157.1c0.4-4.9,1.1-9.8,2.4-14.9c1.2-5,3-10,5.3-14.9c2.3-4.9,5.4-9.1,9.3-12.9
		c3.4-3.4,7.2-6.1,11.4-8.1c4.2-2,8.6-3.6,13.1-4.6c4.5-1.1,9.1-1.8,13.8-2.1c4.7-0.4,9.2-0.5,13.7-0.5c4.4,0,9,0.2,13.7,0.5
		c4.7,0.4,9.3,1.1,13.8,2.1c4.5,1.1,8.8,2.6,13,4.6c4.2,2,8,4.7,11.5,8.1c3.9,3.7,7,8,9.3,12.9c2.3,4.9,4.1,9.8,5.3,14.9
		c1.2,5,2,10,2.4,14.9c0.4,4.9,0.5,9.2,0.5,13.1c0,15.2-1.8,27.4-5.4,36.5c-3.6,9.1-7.7,15.9-12.1,20.3c-3.9,3.7-8.2,6.6-12.9,8.8
		c-4.7,2.1-9.4,3.7-14.2,4.8c-4.8,1.1-9.3,1.7-13.7,2c-4.3,0.3-8.1,0.4-11.3,0.4c-3.2,0-6.9-0.1-11.3-0.4c-4.3-0.3-8.9-0.9-13.7-2
		c-4.8-1.1-9.5-2.7-14.2-4.8c-4.7-2.1-9-5-12.9-8.8c-4.6-4.4-8.7-11.2-12.2-20.3c-3.5-9.1-5.3-21.3-5.3-36.5
		C717.2,166.4,717.4,162,717.7,157.1z M732.2,181.7c0.1,3.9,0.5,7.9,1.2,12.1c0.7,4.2,1.8,8.3,3.3,12.3c1.5,4.1,3.8,7.7,6.8,10.9
		c2.8,3,6,5.4,9.4,7.3c3.4,1.9,7.1,3.2,11,4.1c3.9,0.9,7.8,1.5,11.7,1.7c3.9,0.3,7.6,0.4,11.1,0.4c3.5,0,7.3-0.1,11.1-0.4
		c3.9-0.3,7.8-0.8,11.7-1.7c3.9-0.9,7.6-2.3,11-4.1c3.4-1.9,6.6-4.3,9.4-7.3c2.8-3.2,5-6.8,6.6-10.9c1.6-4.1,2.7-8.2,3.4-12.3
		c0.7-4.1,1.1-8.2,1.2-12.1c0.1-3.9,0.1-7.2,0.1-10.1c0-3-0.1-6.6-0.1-10.7c-0.1-4.2-0.5-8.4-1.2-12.7c-0.7-4.3-1.8-8.5-3.3-12.6
		c-1.5-4.1-3.7-7.7-6.5-10.9c-2.7-3-5.8-5.3-9.4-7c-3.6-1.7-7.4-3-11.4-3.8c-4-0.9-7.9-1.5-11.8-1.7c-3.9-0.3-7.5-0.4-10.9-0.4
		c-3.4,0-7,0.1-10.9,0.4c-3.9,0.3-7.8,0.8-11.8,1.7c-4,0.9-7.8,2.2-11.4,3.8c-3.6,1.7-6.8,4-9.4,7c-2.8,3.2-5,6.8-6.5,10.9
		c-1.5,4.1-2.6,8.3-3.3,12.6c-0.7,4.3-1.1,8.6-1.2,12.7c-0.1,4.2-0.1,7.7-0.1,10.7C732,174.4,732.1,177.8,732.2,181.7z"
        />
        <path
          fill="#3DAFE4"
          d="M881.6,149c0-7.2,0.7-13.9,2.3-20c1.5-6.1,4.9-11.7,10.2-16.8c3-2.8,7.1-5.4,12.3-7.8
		c5.2-2.4,12.6-3.6,22.1-3.6h9.5v12.5h-8.5c-3.9,0-8.4,0.5-13.5,1.6c-5.1,1.1-9.6,3.8-13.5,8.2c-3,3.5-5,7.9-5.8,13
		c-0.9,5.1-1.3,10.5-1.3,16.2v89.1h-13.8V149z"
        />
        <path
          fill="#3DAFE4"
          d="M995.7,224.9c0.3,0.9,0.7,1.8,1.2,2.8c0.4,1,1,1.5,1.7,1.5c0.9,0,1.5-0.6,1.9-1.7c0.4-1.1,0.6-1.9,0.8-2.3
		l30-114.3c1.4-5,3.4-8.1,5.8-9.5c2.5-1.4,4.8-2.1,6.9-2.1c2.1,0,4.4,0.7,6.9,2.1c2.5,1.4,4.4,4.6,5.8,9.5l30,114.3
		c0.2,0.4,0.4,1.1,0.8,2.3c0.4,1.2,1,1.7,1.9,1.7c0.7,0,1.3-0.5,1.7-1.5c0.4-1,0.8-1.9,1.2-2.8l31.6-124.1h13.8l-34.5,130.5
		c-0.9,3-2.3,5.7-4.1,8.1c-1.9,2.4-5,3.6-9.4,3.6c-2.7,0-4.8-0.4-6.5-1.3c-1.7-0.9-3-1.9-4.1-3.2c-1.1-1.2-1.9-2.5-2.5-3.8
		c-0.6-1.3-1-2.5-1.2-3.6l-29.2-109.8c-0.2-0.9-0.4-1.9-0.8-3c-0.4-1.1-0.8-1.7-1.3-1.7c-0.5,0-1,0.6-1.3,1.7
		c-0.4,1.2-0.6,2.2-0.8,3L1012.7,231c-0.4,1.1-0.8,2.3-1.3,3.6c-0.5,1.3-1.3,2.6-2.4,3.8c-1.1,1.2-2.4,2.3-4.1,3.2
		c-1.7,0.9-3.9,1.3-6.5,1.3c-4.4,0-7.6-1.2-9.4-3.6c-1.9-2.4-3.2-5.1-4.1-8.1l-34.5-130.5h13.8L995.7,224.9z"
        />
        <path
          fill="#3DAFE4"
          d="M1233.4,100.8c4.8,0,9.4,0.9,13.8,2.7c4.4,1.8,8,4.2,10.9,7.2c3.7,4.1,6.1,8.7,7,13.9
		c1,5.2,1.5,9.9,1.5,14.2v84.1c0,2-0.2,4.3-0.7,7.2c-0.4,2.8-1.8,5.3-4.1,7.4c-2.1,2-4.7,3.1-7.7,3.4c-3,0.4-5.7,0.5-8.2,0.5h-54.1
		c-4.1,0-8.4-0.3-12.9-0.9c-4.5-0.6-8.7-2.4-12.6-5.4c-3-2.3-5.3-5.2-6.9-8.6c-1.6-3.4-2.8-6.9-3.7-10.3c-0.9-3.4-1.4-6.8-1.6-10.1
		c-0.2-3.3-0.3-6-0.3-8.1c0-5.7,0.7-11.5,2-17.6c1.3-6.1,4.6-11.3,9.7-15.5c3.9-3.2,8.2-5.2,13-6.1c4.8-0.9,9.2-1.3,13.3-1.3h61v-21
		c0-2.3-0.4-5.1-1.3-8.5c-0.9-3.4-2.2-6.1-4-8.2c-1.4-1.8-3.4-3.3-6-4.5c-2.6-1.2-5.5-1.9-8.9-1.9h-68.2v-12.5H1233.4z
		 M1188.6,169.7c-4.1,0-7.4,0.8-9.9,2.4c-2.6,1.6-4.4,3.1-5.4,4.5c-1.8,2.3-3,5.3-3.6,8.9c-0.6,3.6-0.9,7.9-0.9,12.9
		c0,4.2,0.3,8.6,0.9,13.1c0.6,4.5,2.2,8.3,4.6,11.3c1.4,1.8,3.3,3.2,5.7,4.4c2.4,1.2,5.1,1.7,8.1,1.7h56.8c0.9,0,1.9,0,3.1-0.1
		c1.1-0.1,2.2-0.6,3-1.5c0.9-0.9,1.4-1.7,1.6-2.4c0.2-0.7,0.3-1.5,0.3-2.4v-52.8H1188.6z"
        />
        <path
          fill="#3DAFE4"
          d="M1304,149c0-7.2,0.7-13.9,2.2-20c1.5-6.1,4.9-11.7,10.2-16.8c3-2.8,7.1-5.4,12.3-7.8
		c5.2-2.4,12.6-3.6,22.1-3.6h9.6v12.5h-8.5c-3.9,0-8.4,0.5-13.5,1.6c-5.1,1.1-9.6,3.8-13.5,8.2c-3,3.5-5,7.9-5.8,13
		c-0.9,5.1-1.3,10.5-1.3,16.2v89.1H1304V149z"
        />
        <path
          fill="#3DAFE4"
          d="M1485.5,229.3c-0.5,2.9-1.5,5.3-2.9,7c-1.9,2.5-4.4,3.9-7.4,4.4c-3,0.4-5.7,0.7-8,0.7h-43.8
		c-3.5,0-7.4-0.3-11.5-0.9c-4.2-0.6-8.4-1.9-12.6-3.8c-4.2-1.9-8.2-4.8-11.9-8.5c-3.7-3.7-6.9-8.8-9.5-15.1
		c-2.8-6.7-4.6-14.1-5.3-22c-0.7-8-1.1-15.8-1.1-23.6c0-7.8,0.5-15.5,1.6-23.2c1.1-7.7,4.1-15.2,9-22.4c3.2-4.6,6.7-8.3,10.6-11
		c3.9-2.7,8-4.9,12.2-6.4c4.2-1.5,8.5-2.5,12.7-2.9c4.2-0.4,8.3-0.7,12.2-0.7h42.7V55.7h13.8v165.2
		C1486.2,223.6,1486,226.3,1485.5,229.3z M1426.6,113.2c-2.6,0-5.5,0.2-8.5,0.5c-3,0.4-6,1.1-9,2.3c-3,1.2-5.9,2.8-8.6,4.9
		c-2.7,2.1-5.2,4.9-7.3,8.2c-2.5,4.1-4.2,9.3-5.3,15.6c-1.1,6.4-1.6,14.6-1.6,24.7c0,6.4,0.1,11.7,0.4,16c0.3,4.3,0.6,8,1.1,10.9
		c0.4,2.9,0.9,5.2,1.3,6.9c0.4,1.7,0.8,3,1.2,3.8c1.8,4.8,4.2,8.6,7.2,11.5c3,2.9,6.2,5.1,9.6,6.6c3.4,1.5,6.8,2.5,10.2,2.9
		c3.5,0.4,6.6,0.7,9.4,0.7h35.3c0.9,0,2.2-0.1,4.1-0.3c1.9-0.2,3.3-0.8,4.4-1.9c0.9-0.9,1.5-2.4,1.7-4.5c0.3-2.1,0.4-3.9,0.4-5.3
		V113.2H1426.6z"
        />
      </g>
      <g id="Icon">
        <polygon fill="#54BEED" points="191,242.4 120.2,148.2 191,54.1 261.7,148.2 	" />
        <polygon fill="#3DAFE4" points="120.2,148.3 50,54.1 261.7,148.3 	" />
        <polygon fill="#77CDF4" points="191,54.1 50,54.1 155.7,101.1 	" />
        <polygon fill="#77CDF4" points="50,242.4 191,242.4 155.7,195.4 	" />
        <polygon fill="#0C96D4" points="120.2,148.2 50,242.4 261.7,148.2 	" />
      </g>
      <g id="R">
        <path
          fill="#3DAFE4"
          d="M1511.2,75c0-6.9,2.4-12.8,7.3-17.7c4.9-4.9,10.8-7.3,17.7-7.3c6.9,0,12.8,2.4,17.7,7.3
		c4.9,4.9,7.3,10.8,7.3,17.7c0,6.9-2.4,12.8-7.3,17.7c-4.9,4.9-10.8,7.3-17.7,7.3c-6.9,0-12.8-2.4-17.7-7.3
		C1513.7,87.9,1511.2,82,1511.2,75z M1514.4,75c0,6.1,2.1,11.2,6.4,15.4c4.3,4.2,9.4,6.4,15.5,6.4c6,0,11.1-2.1,15.4-6.4
		c4.2-4.2,6.4-9.4,6.4-15.4c0-6-2.1-11.1-6.4-15.4c-4.2-4.2-9.4-6.4-15.4-6.4c-6.1,0-11.2,2.1-15.5,6.4
		C1516.6,63.9,1514.4,69,1514.4,75z M1526.6,89.3V60.8h10.5c3.2,0,5.7,0.7,7.5,2.2c1.8,1.5,2.8,3.6,2.8,6.4c0,2.3-0.6,4.2-1.8,5.6
		c-1.2,1.4-2.7,2.3-4.5,2.8l7.5,11.5h-4.7l-6.9-10.8h-6.4v10.8H1526.6z M1530.6,74.7h6.4c4.3,0,6.4-1.7,6.4-5.2
		c0-3.5-2.2-5.2-6.6-5.2h-6.2V74.7z"
        />
      </g>
    </svg>
  );
};
//
// return (
//   <img
//     src={isLogoGray ? LogoIconGray : LogoIconWhite}
//     className={classes.logo}
//     alt={'logo bizforward'}
//   />
// );
// };
