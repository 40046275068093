import { Box, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SearchTextFieldStyles } from '../Styles/SearchTextField';
import SearchIcon from '../../../../Icons/Search.svg';
import RemoveSearchIcon from '../../../../Icons/Remove-search.svg';
import clsx from 'clsx';

interface SearchProps {
  name: string;
  placeholder: string;
  filterHandleChange: () => void;
  focusCallback?: () => void;
}

const useStyles = makeStyles<Theme>(() => {
  return {
    root: {
      marginBottom: 36,
    },
  };
});

export const SearchTextFieldStyled = SearchTextFieldStyles(TextField);

export const SearchTextField = (props: SearchProps): JSX.Element => {
  const { name, placeholder, filterHandleChange, focusCallback } = props;
  const { control } = useFormContext();
  const classes = useStyles();

  const handleChange = (e: any, onchange: any) => {
    onchange(e);

    setTimeout(() => {
      filterHandleChange();
    }, 500);
  };

  return (
    <Box className={clsx(classes.root, 'searchTextField')}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <SearchTextFieldStyled
              onFocus={focusCallback}
              color={'secondary'}
              onChange={e => handleChange(e, onChange)}
              value={value}
              placeholder={placeholder}
              variant={'outlined'}
              type={'text'}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: <img src={SearchIcon} className={'searchIcon'} />,
                endAdornment: value.length > 0 && (
                  <img
                    src={RemoveSearchIcon}
                    className={'removeSearchIcon'}
                    onClick={() => handleChange('', onChange)}
                  />
                ),
              }}
            />
          );
        }}
      />
    </Box>
  );
};
