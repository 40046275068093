import { createStyles, withStyles } from '@material-ui/core';
import CheckIcon from '../../../../Icons/Check.svg';

export const FormTextFieldStyles = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },
      '&.filledInput  .MuiInputBase-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B9A06A',
      },

      '&.filledInput .MuiInputBase-root:not(.Mui-focused)': {
        paddingRight: 34,
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'absolute',
          backgroundImage: `url(${CheckIcon})`,
          backgroundRepeat: 'no-repeat',
          height: 14,
          width: 18,
          right: 16,
          zIndex: 1,
        },
      },

      '&.filledInput  .MuiInputBase-root:not(.Mui-focused) .tooltipIcon': {
        display: 'none',
      },

      '& input': {
        fontSize: 16,
        caretColor: theme.palette.secondary.main,
        '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },

      '& .Mui-error input': {
        color: theme.palette.error.main,
      },
      '& .MuiInputBase-root': {
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        '&.Mui-error': {
          '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.error.main,
          },
        },
      },
      '& .MuiFormLabel-root': {
        '&.MuiFormLabel-filled': {
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
        '&.Mui-focused': {
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: theme.palette.error.main,
      },
      '& .MuiInputLabel-outlined': {
        fontSize: 16,
        lineHeight: '20px',
      },

      '& .tooltipIcon': {
        cursor: 'pointer',
        height: 54,
        width: 54,
        padding: 14,
        marginLeft: 8,
        position: 'relative',
        left: 8,
      },

      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 16,
      },
      '& .MuiSelect-icon': {
        marginRight: 10,
      },
    },
  }),
);
