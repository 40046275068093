import { Box, ClickAwayListener, Fade, IconButton, Snackbar, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useContext, useEffect } from 'react';
import CloseGreyIcon from '../../../Icons/Close-grey.svg';
import { AppContext } from '../../../Context/Context';
import { useFormSnackbar } from '../../../Hooks/useFormSnackbar';

const useStyles = makeStyles<Theme>(theme => {
  return {
    formSnackbar: {
      top: 36,
      right: 36,
      zIndex: 1401,
      background: '#FF1155',
      padding: 12,
      width: 320,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        right: 0,
      },
      borderRadius: 4,
      boxShadow: '0 6px 20px 0 rgba(0,0,0,0.16)',
      display: 'flex',
      justifyContent: 'space-between',
      transform: 'unset',
      left: 'unset',
      bottom: 'unset',
      alignItems: 'flex-start',
    },
    snackbarHeader: {
      color: '#fff',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },
    closeIcon: {
      padding: 0,
      '& img': {
        width: 20,
        height: 20,
        filter: 'brightness(0) invert(1)',
      },
    },
  };
});

export const FormSnackbar = (): JSX.Element => {
  const classes = useStyles();

  const {
    state: { steps },
  } = useContext(AppContext);
  const { formSnackbar, formSnackbarMessage } = steps;
  const handleFormSnackbar = useFormSnackbar();

  return (
    <Snackbar
      open={formSnackbar}
      autoHideDuration={10000}
      onClose={() => handleFormSnackbar(false, '')}
      className={classes.formSnackbar}>
      <>
        <Typography variant={'body2'} className={classes.snackbarHeader}>
          {formSnackbarMessage}
        </Typography>
        <IconButton className={classes.closeIcon} onClick={() => handleFormSnackbar(false, '')}>
          <img alt={''} src={CloseGreyIcon} />
        </IconButton>
      </>
    </Snackbar>
  );
};
