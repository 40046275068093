import { ActionMap } from './Actions';
import { CheckboxListItem, GroupOpt } from '../Types/FormFields';
import { RegDataTypes } from '../Types/ResponseType';
import { ShortRegModel } from '../Domain/components/Steps/ShortRegistration/interface';
import { CompetenceFields } from '../Domain/components/Steps/Competences/interface';
import { TechStackFields, TechStackModel } from '../Domain/components/Steps/TechStack/interface';
import {
  IndustryKnowledgeFields,
  IndustryKnowledgeModel,
} from '../Domain/components/Steps/IndustryKnowledge/interface';
import { LanguageModel, LanguageModelFields } from '../Domain/components/Steps/Languages/interface';
import { DetailsModel, DetailsModelFields } from '../Domain/components/Steps/Details/interface';

export enum StepsTypes {
  SET_COMPLETED_STEP = 'SET_COMPLETED_STEP',
  SET_STEP = 'SET_STEP',
  SET_PRETEND_STEP = 'SET_PRETEND_STEP',
  SET_FIELDS = 'SET_FIELDS',
  SET_REG_DATA = 'SET_REG_DATA',
  SET_FORM_SNACKBAR = 'SET_FORM_SNACKBAR',
  SET_SHORT_REG_DATA = 'SET_SHORT_REG_DATA',
  SET_SIGNUP_ID = 'SET_SIGNUP_ID',
}

export interface StepsAction {
  type: StepsTypes;
  payload: any;
}

// export interface FieldsTypes {
//   firstName: string;
//   surname: string;
//   gender: string | '';
//   email: string;
//   password: string;
//   passwordConfirmation: string;
//   areaCode: string;
//   phone: number | '';
//   website: string;
//   postCode: number | '';
//   residence: string;
//   country: number;
//   regions: CheckboxListItem[];
//   languages: [];
//   competences: CheckboxListItem[];
//   techsGroupsOpt: GroupOpt[];
//   sectors: CheckboxListItem[];
//   uploadedProfile: File | null;
//   comment: string;
//   available_from: string;
//   days_per_week: number | '';
//   daily_rate: number | '';
//   contract_staffing: boolean;
//   newsletter: boolean;
//   xing_url: string;
//   linkedin_url: string;
//   insurance: boolean;
//   pro_bono: boolean;
//   uploadedGenDocs: File[];
//   profileUrl: string;
//   isFreelancer: boolean;
//   isGdpr: boolean;
// }

export type FieldsTypes = CompetenceFields &
  TechStackFields &
  IndustryKnowledgeFields &
  LanguageModelFields &
  DetailsModelFields;

export interface StepsState {
  completedStep: number;
  step: number;
  pretendStep: number;
  // fields: FieldsTypes;
  fields: FieldsTypes;
  regData: RegDataTypes | null;
  formSnackbar: boolean;
  formSnackbarMessage: string;
  shortReg: ShortRegModel;
  signupId: string;
}

export type StepsPayload = {
  [StepsTypes.SET_COMPLETED_STEP]: {
    completedStep: number;
  };
  [StepsTypes.SET_STEP]: {
    step: number;
  };
  [StepsTypes.SET_PRETEND_STEP]: {
    pretendStep: number;
  };
  [StepsTypes.SET_FIELDS]: {
    fields: FieldsTypes;
  };
  [StepsTypes.SET_REG_DATA]: {
    regData: RegDataTypes | null;
  };
  [StepsTypes.SET_FORM_SNACKBAR]: {
    formSnackbar: boolean;
    formSnackbarMessage: string;
  };
  [StepsTypes.SET_SHORT_REG_DATA]: {
    fields: ShortRegModel;
  };
  [StepsTypes.SET_SIGNUP_ID]: {
    signupId: string;
  };
};

export type StepsActions = ActionMap<StepsPayload>[keyof ActionMap<StepsPayload>];

export const StepsReducer = (state: StepsState, action: StepsActions) => {
  switch (action.type) {
    case StepsTypes.SET_FIELDS:
      return {
        ...state,
        fields: action.payload.fields,
      };
    case StepsTypes.SET_REG_DATA:
      return {
        ...state,
        regData: action.payload.regData,
      };
    case StepsTypes.SET_FORM_SNACKBAR:
      return {
        ...state,
        formSnackbar: action.payload.formSnackbar,
        formSnackbarMessage: action.payload.formSnackbarMessage,
      };
    case StepsTypes.SET_COMPLETED_STEP:
      return {
        ...state,
        completedStep: action.payload.completedStep,
      };
    case StepsTypes.SET_PRETEND_STEP:
      return {
        ...state,
        pretendStep: action.payload.pretendStep,
      };
    case StepsTypes.SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    case StepsTypes.SET_SHORT_REG_DATA:
      return {
        ...state,
        shortReg: action.payload.fields,
      };
    case StepsTypes.SET_SIGNUP_ID:
      return {
        ...state,
        signupId: action.payload.signupId,
      };
    default:
      return state;
  }
};
