import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { AppContext } from '../../../../Context/Context';
import { GroupApi, RegDataTypes } from '../../../../Types/ResponseType';
import { CheckboxListItem } from '../../../../Types/FormFields';
import { SearchFilter } from '../../SearchFilter';
import { GroupSelection } from '../../GroupSelection';

import clsx from 'clsx';
import { GroupItemsCheckBox } from '../../GroupItemsCheckbox';

interface CompetencesProps {
  methods: any;
  handleStep: (value: number) => void;
  step: number;
  saveFields: () => void;
  onSubmit: () => void;
}

export interface HandleUpdateCheckParams {
  parent: string;
  id: string;
  label: string;
  value: boolean;
}

export const Competences = (props: CompetencesProps): JSX.Element => {
  const classes = StepFormStyles();
  const { methods, handleStep, step, saveFields } = props;
  const {
    state: { steps },
  } = useContext(AppContext);
  const { regData } = steps;
  const { modules } = regData as RegDataTypes;
  const [selectedCompetences, setSelectedCompetences] = useState<GroupApi[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentGroup, setCurrentGroup] = useState('');
  const [groupItems, setGroupItems] = useState<GroupApi[]>([]);

  const onSubmitPrevious = (data: any) => {
    saveFields();
    const newStep = step - 1;
    handleStep(newStep);
  };

  const handlePreviousStep = useCallback(() => {
    methods.handleSubmit(onSubmitPrevious)();
  }, []);

  const onSubmit = (data: any) => {
    props.onSubmit();
  };

  const showGroupItems = (group: string) => {
    setCurrentGroup(group);
  };
  const closeSelection = () => {
    setCurrentGroup('');
    setSearchValue('');
  };

  const getCount = (group: GroupApi) => {
    return group.entries.filter(item => item.value).length;
  };

  const getTotal = () => {
    return selectedCompetences.reduce(
      (partialsum, tech) => partialsum + tech.entries.filter(item => item.value).length,
      0,
    );
  };

  const getItems = (groupLabel: string) => {
    const filteredCompetences = selectedCompetences.filter(
      group => group.groupLabel === groupLabel,
    );
    return filteredCompetences;
  };

  const handleFilterSearch = (searchValue: string) => {
    if (searchValue === '') {
      setSearchValue('');
      if (currentGroup === '') {
        setGroupItems([]);
      }
      return;
    }

    const globalSearch = currentGroup === '';
    const groups = globalSearch ? selectedCompetences : getItems(currentGroup);

    const filteredGroups = groups.map(groupItem => {
      return {
        groupLabel: groupItem.groupLabel,
        entries: groupItem.entries.filter(item =>
          globalSearch
            ? item.label.toLowerCase().startsWith(searchValue.toLowerCase())
            : item.label.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      };
    });

    setGroupItems(filteredGroups);
    setSearchValue(searchValue);
  };

  const updateItems = ({ parent, id, label, value }: HandleUpdateCheckParams) => {
    let currentValues: CheckboxListItem[] = methods.getValues('competences');

    const competenceIndex = selectedCompetences.findIndex(
      competence => competence.groupLabel === parent,
    );
    if (competenceIndex > -1) {
      const updatedCompetences = structuredClone(selectedCompetences);
      const updateItem = updatedCompetences[competenceIndex]?.entries.find(item => item.id === id);
      if (updateItem) {
        updateItem.value = value;

        if (value) {
          currentValues = [
            ...currentValues,
            ...[
              {
                label: label,
                id: id,
                groupLabel: parent,
              },
            ],
          ];
        } else {
          currentValues = currentValues.filter(item => item.id !== id);
        }
        methods.setValue('competences', currentValues);
        setSelectedCompetences(updatedCompetences);
      }
    }
  };

  useEffect(() => {
    if (searchValue !== '') {
      return;
    }

    if (currentGroup !== '') {
      setGroupItems(getItems(currentGroup));
      return;
    }
    setSearchValue('');
    setGroupItems([]);
  }, [currentGroup, selectedCompetences, searchValue]);

  useEffect(() => {
    const savedCompetences: CheckboxListItem[] = methods.getValues('competences');

    const updatedCompetences = modules.competences;

    savedCompetences.forEach(competence => {
      const groupIndex = updatedCompetences.findIndex(
        group => group.groupLabel === competence.groupLabel,
      );
      if (groupIndex > -1) {
        const itemToUpdate = updatedCompetences[groupIndex].entries.find(
          item => item.id === competence.id,
        );
        if (itemToUpdate) {
          itemToUpdate.value = true;
        }
      }
      setSelectedCompetences(updatedCompetences);
    });

    setSelectedCompetences(modules.competences);
  }, []);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>
        <FormHeader label="Fachexpertise" />
        <Typography variant={'body2'} className={classes.subHeader}>
          Wähle bis zu <strong>5 Kompetenzen</strong>, in denen Du über eine herausragende Expertise
          verfügst.
        </Typography>
        <Grid container direction={'column'} className={classes.fieldsWrapper}>
          <SearchFilter
            placeholder={`z. B. Data Science`}
            filterHandleChange={handleFilterSearch}
            searchValue={searchValue}
          />
          <div className={classes.selectionWrapper}>
            <div className={clsx({ [classes.opacityDisabled]: searchValue })}>
              {selectedCompetences.map((group, ix) => (
                <GroupSelection
                  label={group.groupLabel}
                  key={`${group.groupLabel + ix}`}
                  onGroupSelection={showGroupItems}
                  selectionNumber={getCount(group)}
                />
              ))}
            </div>
            <GroupItemsCheckBox
              groups={groupItems}
              closeSelection={closeSelection}
              updateItems={updateItems}
              searchValue={searchValue}
            />
            <br />
            <br />
            <Button className={classes.nextBtn} type={'submit'}>
              <Box className={classes.btnContentWrapper}>
                Weiter
                {getTotal() > 0 && <Box className={classes.checkedNumber}>{getTotal()}</Box>}
              </Box>
            </Button>
            {step > 1 && (
              <Button
                className={classes.backBtn}
                color={'primary'}
                variant={'outlined'}
                onClick={handlePreviousStep}>
                Zurück
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
