import { Box, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Logo } from '../App/Shared/Logo/Logo';
import { Contact } from '../App/Shared/Contact/Contact';
import { FormSnackbar } from '../App/Shared/Form/FormSnackbar';
import React from 'react';
import clsx from 'clsx';

interface TwoRowsLayoutProps {
  imgSrc?: string;
  rightContent: React.ReactNode;
  smallImage?: boolean;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    container: {
      minHeight: '100vh',
      width: 1360,
      margin: '0 auto',
      paddingTop: 36,
      [theme.breakpoints.down('md')]: {
        width: 1024,
      },
    },
    layer: {
      position: 'absolute',
      backgroundColor: '#F8F8F8',
      width: '50%',
      height: '100%',
      zIndex: -1,
      left: 0,
      top: 0,
    },
    bigImage: {
      height: 410,
      width: 410,
      margin: '10vh auto',
      [theme.breakpoints.up('lg')]: {
        height: 576,
        width: 576,
      },
    },

    smallImage: {
      height: 256,
      width: 256,
      marginTop: 256,
      marginLeft: 160,
    },
    leftPart: {
      display: 'flex',
      flexDirection: 'column',
    },
    placeholder: {
      flex: 1,
    },
  };
});

export const TwoRowsLayout = (props: TwoRowsLayoutProps): JSX.Element => {
  const { imgSrc, rightContent, smallImage } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Box className={classes.layer} />
        <FormSnackbar />
        <Grid item xs={6} className={clsx(classes.leftPart)}>
          <Logo isLogoGray={true} />

          {!imgSrc && <Box className={classes.placeholder} />}
          {imgSrc && (
            <img
              src={imgSrc}
              className={clsx({
                [classes.bigImage]: !smallImage,
                [classes.smallImage]: smallImage,
              })}
              alt={'big image'}
            />
          )}
          <Box className={classes.placeholder} />
          <Contact context={'grayView'} />
        </Grid>
        <Grid item xs={6}>
          {rightContent}
        </Grid>
      </Grid>
    </>
  );
};
