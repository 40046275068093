import React from 'react';

export const IconCloseLarge: React.FC = () => (
  <svg width="36px" height="36px" viewBox="0 0 36 36">
    <polygon
      fill="#000000"
      points="28.5 9.615 26.385 7.5 18 15.885 9.615 7.5 7.5 9.615 15.885 18 7.5 26.385 9.615 28.5 18 20.115 26.385 28.5 28.5 26.385 20.115 18"
    />
  </svg>
);
