import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Logo } from '../App/Shared/Logo/Logo';
import { SecondStep } from '../Domain/components/SecondStep';
import { ThirdStep } from '../Domain/components/ThirdStep';
import { FourthStep } from '../Domain/components/FourthStep/FourthStep';
import { FifthStep } from '../Domain/components/FifthStep/FifthStep';
import { SixthStep } from '../Domain/components/SixthStep';
import { DocumentsStep } from '../Domain/components/DocumentsStep';
import { FinalStep } from '../Domain/components/FinalStep';
import { Contact } from '../App/Shared/Contact/Contact';
import { FirstStep } from '../Domain/components/FirstStep';
import { FormSnackbar } from '../App/Shared/Form/FormSnackbar';
import { MobileSidebar } from '../Domain/components/Sidebar/MobileSidebar';
import React, { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { AppContext } from '../Context/Context';

interface MobileStepsLayout {
  children: JSX.Element;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    container: {
      minHeight: '100vh',
      width: '100%',
      margin: '0 auto',
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.up('sm')]: {
        width: 480,
      },
    },
    sidebar: {
      paddingTop: 64,
    },
    bottomLogoWrapper: {
      marginBottom: 31,
    },
  };
});

export const MobileWrapper: React.FC = ({ children }): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={clsx(classes.container, classes.sidebar)}
      direction={'column'}
      spacing={2}>
      <FormSnackbar />

      <Grid item>
        <Logo />
      </Grid>
      <Grid item container>
        {children}
      </Grid>
      <Divider className={classes.divider} />
      <Grid item>
        <Box className={classes.bottomLogoWrapper}>
          <Logo />
        </Box>
        <Contact />
      </Grid>
    </Grid>
  );
};
