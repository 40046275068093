import { Button, makeStyles } from '@material-ui/core';
import { FormTextFieldStyled } from '../FormTextField';
import ArrowOpen from '../../../../Icons/Arrow-open.svg';
import React, { useCallback } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { CheckboxListItemApi } from '../../../../Types/ResponseType';
import { CheckboxListItem } from '../../../../Types/FormFields';

interface SelectMuiBtn {
  handleOpen: () => void;
  label: string;
  name: string;
  options: CheckboxListItemApi[];
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    modalBtn: {
      padding: 0,
      width: '100%',
      cursor: 'pointer',
      textTransform: 'unset',
      '& .MuiInputBase-root.Mui-disabled': {
        cursor: 'unset',
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FF1155',
      },
      '& input': {
        cursor: 'pointer',
      },
    },
    selectIcon: {
      width: 24,
      height: 24,
    },
  };
});

export const SelectMuiBtn = (props: SelectMuiBtn): JSX.Element => {
  const { handleOpen, label, name, options } = props;
  const classes = useStyles();
  const { watch, formState } = useFormContext();

  const getBtnValue = useCallback(() => {
    const currentState = watch(name);
    const currentLabels = currentState.map((checkbox: CheckboxListItem) => checkbox.label);
    const inputValue = currentLabels.join(', ');
    return inputValue;
  }, [watch, name]);

  return (
    <Button className={classes.modalBtn} onClick={handleOpen}>
      <FormTextFieldStyled
        // error={formState?.errors?.[name]}
        disabled
        InputProps={{
          endAdornment: <img src={ArrowOpen} className={classes.selectIcon} />,
        }}
        color={'secondary'}
        value={getBtnValue()}
        label={label}
        variant={'outlined'}
      />
    </Button>
  );
};
