import { Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      alignItems: 'center',
      margin: 72,
      marginTop: 300,
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 24,
        margin: 0,
      },
    },
    header: {
      color: '#000',
      fontSize: 36,
      fontWeight: 'bold',
      lineHeight: '48px',
      marginBottom: 36,
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
        lineHeight: '36px',
      },
    },
    subHeader: {
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.primary.main,
      marginBottom: 36,
    },
    linkInsideText: {},
    mobileImage: {
      width: '50%',
      height: 'auto',
      marginBottom: 16
    },
  };
});

export interface FailedDataProps {
  mobile: boolean;
}

export const FailedData = (props: FailedDataProps): JSX.Element => {
  const classes = useStyles();
  const { mobile } = props;
  const mobileView = useMediaQuery('(max-width:1024px)');

  return (
    <Grid className={classes.root} container direction={'column'} xs={!mobileView && 7}>
      {mobile && <img className={classes.mobileImage} alt={''} src={'/Images/warning.svg'} />}
      <Typography variant={'body2'} className={classes.header}>
        Leider hat das nicht geklappt.
      </Typography>

      <Typography variant={'body2'} className={classes.subHeader}>
        Bitte melde Dich beim bizforward Team unter{' '}
        <Link
          href="mailto:info@bizforward.de"
          variant="body2"
          color={'secondary'}
          style={{ fontSize: 16 }}>
          info@bizforward.de
        </Link>{' '}
        und wir schicken Dir einen neuen Link zu!
      </Typography>
    </Grid>
  );
};
