import { Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Logo } from '../App/Shared/Logo/Logo';
import { Sidebar } from '../Domain/components/Sidebar/Sidebar';
import { Help } from '../App/Shared/Help/Help';
import { SixthStep } from '../Domain/components/SixthStep';
import { DocumentsStep } from '../Domain/components/DocumentsStep';
import { FinalStep } from '../Domain/components/FinalStep';
import { FormSnackbar } from '../App/Shared/Form/FormSnackbar';
import { useCallback, useEffect, useState } from 'react';
import { CompetencesForm } from '../Domain/components/Steps/Competences';
import { TechStackForm } from '../Domain/components/Steps/TechStack';
import { IndustryKnowledgeForm } from '../Domain/components/Steps/IndustryKnowledge';
import { LanguagesForm } from '../Domain/components/Steps/Languages';
import { DetailsForm } from '../Domain/components/Steps/Details';

interface StepsLayoutProps {
  handleStep: (
    value: number,
    files?: {
      documents: File[];
    },
  ) => void;
  step: number;
  handleCompletedStep: (value: number) => void;
  handlePretendStep: (value: number) => void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    container: {
      minHeight: '100vh',
      width: 1360,
      margin: '0 auto',
      paddingTop: 36,
      [theme.breakpoints.down('md')]: {
        width: 1024,
      },
    },
  };
});

export const StepsLayout = (props: StepsLayoutProps): JSX.Element => {
  const { handleStep, step, handlePretendStep, handleCompletedStep } = props;
  const classes = useStyles();
  const [documents, setDocuments] = useState<File[]>([]);

  const handleStepMiddleWare = useCallback(
    (step: number, files?: { documents: File[] }) => {
      if (files) {
        setDocuments(files.documents);
      }

      handleStep(step);
    },
    [handleStep, setDocuments],
  );

  return (
    <Grid container className={classes.container} direction={'column'}>
      <FormSnackbar />
      <Grid container item justifyContent={'space-between'}>
        <Logo />
        <Help />
      </Grid>
      <Grid container item>
        <Grid item xs={5}>
          <Sidebar step={step} handlePretendStep={handlePretendStep} />
        </Grid>
        <Grid item xs={7}>
          {step === 1 && (
            <CompetencesForm
              handleStep={handleStep}
              step={step}
              handlePretendStep={handlePretendStep}
              handleCompletedStep={handleCompletedStep}
            />
          )}
          {step === 2 && (
            <TechStackForm
              handleStep={handleStep}
              step={step}
              handlePretendStep={handlePretendStep}
              handleCompletedStep={handleCompletedStep}
            />
          )}
          {step === 3 && (
            <IndustryKnowledgeForm
              handleStep={handleStep}
              step={step}
              handlePretendStep={handlePretendStep}
              handleCompletedStep={handleCompletedStep}
            />
          )}
          {step === 4 && (
            <LanguagesForm
              handleStep={handleStep}
              step={step}
              handlePretendStep={handlePretendStep}
              handleCompletedStep={handleCompletedStep}
            />
          )}
          {step === 5 && (
            <DetailsForm
              handleStep={handleStep}
              step={step}
              handlePretendStep={handlePretendStep}
            />
          )}
          {/*{step === 6 && (*/}
          {/*  <SixthStep*/}
          {/*    handleStep={handleStep}*/}
          {/*    step={step}*/}
          {/*    handlePretendStep={handlePretendStep}*/}
          {/*    handleCompletedStep={handleCompletedStep}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{step === 7 && (*/}
          {/*  <DocumentsStep*/}
          {/*    handleStep={handleStepMiddleWare}*/}
          {/*    step={step}*/}
          {/*    handlePretendStep={handlePretendStep}*/}
          {/*    handleCompletedStep={handleCompletedStep}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{step === 8 && (*/}
          {/*  <FinalStep*/}
          {/*    handleStep={handleStep}*/}
          {/*    step={step}*/}
          {/*    documents={documents}*/}
          {/*    handlePretendStep={handlePretendStep}*/}
          {/*  />*/}
          {/*)}*/}
        </Grid>
      </Grid>
    </Grid>
  );
};
