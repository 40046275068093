import { Controller, useFormContext } from 'react-hook-form';
import React, { useContext, useState } from 'react';
import { FormTextFieldStyles } from './Styles/FormTextFieldStyles';
import { MenuItem } from '@material-ui/core';
import { AppContext } from '../../../Context/Context';
import TextField from '@material-ui/core/TextField';

interface FormInputTextProps {
  name: string;
  label: string;
  type?: string;
  tooltipTitle?: string;
  placeholder?: string;
}

export const FormTextFieldStyled = FormTextFieldStyles(TextField);

export const FormDropdown = (props: FormInputTextProps) => {
  const { name, label, type = 'text', placeholder } = props;
  const { control, setValue } = useFormContext();
  const { state } = useContext(AppContext);

  const countries = state.steps.regData?.modules?.contact?.countries || {};
  const [selectedCountry, setSelectedCountry] = useState({
    id: '2000',
    value: 'Deutschland',
  });

  const handleChangeTest = (id: string, value: string) => {
    console.log(id, value, name);
    setValue(name, id);
    setSelectedCountry({
      id,
      value,
    });
  };

  const countryOptions = Object.keys(countries)?.map(country => ({
    label: countries?.[country],
    value: country,
  }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { invalid, error } }) => {
        return (
          <>
            <FormTextFieldStyled
              error={invalid}
              color={'secondary'}
              onChange={e => {
                handleChangeTest(e.currentTarget.id, e.target.value);
              }}
              value={selectedCountry.value}
              select
              label={label}
              placeholder={placeholder ? placeholder : undefined}
              variant={'outlined'}
              type={type}>
              {countryOptions.map(country => (
                <MenuItem key={country.value} value={country.label} id={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </FormTextFieldStyled>
          </>
        );
      }}
    />
  );
};
