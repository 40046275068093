import { createStyles, withStyles } from '@material-ui/core';

export const FormCheckboxStyles = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
      color: '#ccc',
    },
  }),
);
