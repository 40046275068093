import { Tooltip, withStyles } from '@material-ui/core';

export const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.primary.main,
    maxWidth: 341,
    marginLeft: 43,
    [theme.breakpoints.up('md')]: {
      '& .MuiTooltip-arrow': {
        '&::before': {
          backgroundColor: 'white',
          border: '1px solid #CCC',
        },
      },
      '&.MuiTooltip-tooltip': {
        padding: '8.5px 0 8.5px 11.5px',
        borderRadius: 0,
        position: 'relative',
        '&.MuiTooltip-tooltipPlacementRight': {
          borderLeft: '1px solid #CCC',
          padding: '8.5px 0 8.5px 11.5px',
        },
        '&.MuiTooltip-tooltipPlacementLeft': {
          borderRight: '1px solid #CCC',
          padding: '8.5px 11.5px 8.5px 0',
        },
        '&.MuiTooltip-tooltipPlacementTop': {
          borderBottom: '1px solid #CCC',
          padding: '0 8.5px 11.5px 8.5px',
        },
        '&.MuiTooltip-tooltipPlacementBottom': {
          borderTop: '1px solid #CCC',
          padding: '11.5px 8.5px 0 8.5px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      fontSize: 12,
      lineHeight: '16px',
      width: 295,
    },
  },
}))(Tooltip);