import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const FormModalStyles = makeStyles<Theme>((theme) => {
  return {
    dialogTitle: {
      padding: '50px 36px 0 36px',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 20px 28px',
        '& h2': {
          display: 'flex',
        },
      },
    },
    dialogContent: {
      display: 'flex',
      justifyContent: 'center',
      padding: '24px 36px 0 36px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },
    },
    header: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      color: '#000',
      marginLeft: 18,
    },
    closeButton: {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: 24,
          height: 24,
        },
      },
    },
    cta: {
      textTransform: 'unset',
      fontSize: 16,
      lineHeight: '18px',
      fontWeight: 500,
      width: 400,
      margin: '0 auto',
      paddingTop: 15,
      paddingBottom: 15,
      borderRadius: 4,
      borderWidth: 2,
      marginBottom: 72,
      background: '#fff',
      '&:hover': {
        borderWidth: 2,
      },
      [theme.breakpoints.down('sm')]: {
        width: 335,
      },
    },
  };
});
