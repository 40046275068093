import { createTheme, Theme } from '@material-ui/core/styles';

// A custom theme for this app
export const FreelancerSignUpTheme: Theme = createTheme({
  typography: {
    fontFamily: [`'Maven Pro'`, 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ',',
    ),
  },
  palette: {
    primary: {
      main: '#666666',
    },
    secondary: {
      main: '#32AFE4',
    },
    error: {
      main: '#FF1155',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1360,
      xl: 1536,
    },
  },
});