import { createStyles, withStyles } from '@material-ui/core';

export const FormNumTextFieldStyles = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },

      '& input': {
        fontSize: 16,
        caretColor: 'transparent',
        '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.secondary.main} !important`,
      },

      '& .Mui-error input': {
        color: theme.palette.error.main,
      },
      '& .MuiInputBase-root': {
        '&.Mui-focused': {
          color: theme.palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        '&.Mui-error': {
          '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.error.main,
          },
        },
      },
      '& .MuiFormLabel-root': {
        '&.MuiFormLabel-filled': {
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
        '&.Mui-focused': {
          color: `${theme.palette.secondary.main} !important`,

          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: theme.palette.error.main,
      },
      '& .MuiInputLabel-outlined': {
        fontSize: 16,
        lineHeight: '20px',
      },

      '& .tooltipIcon': {
        cursor: 'pointer',
        height: 24,
        width: 28,
      },

      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 16,
      },
    },
  }),
);
