import { FormGroup, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxRadioListItem, GroupOpt, LevelObj } from '../../../../Types/FormFields';
import { CboxRadioListItem } from './CboxRadioListItem';
import { SaveFieldsParams } from '../../../../Domain/components/Steps/Languages';

interface CheckboxRadioGroupsProps {
  name: string;
  radioOptions: string[] | LevelObj[];
  saveFields?(params?: SaveFieldsParams): void;
  filter?: string;
  options?: GroupOpt[];
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    formGroup: {
      marginBottom: 12,
    },
  };
});

export const CheckboxRadioGroups = (props: CheckboxRadioGroupsProps): JSX.Element => {
  const { options } = props;
  const classes = useStyles();
  const { name, radioOptions, saveFields } = props;
  const { getValues, setValue, watch, control } = useFormContext();

  const listCheckboxes = useMemo(() => {
    if (getValues('search').length > 0) {
      return getValues('filteredCheckboxes');
    } else {
      return getValues(name);
    }
  }, [getValues('search'), watch('filteredCheckboxes')]);

  const handleChange = useCallback(
    (e, onChange: any, checkbox: CheckboxRadioListItem, mainName: string) => {
      onChange(e);

      if (mainName !== name) {
        const mainList = getValues(name);
        const mainListCheckboxIdx = mainList.findIndex(
          (listItem: CheckboxRadioListItem) => listItem.label === checkbox.label,
        );

        const mainListCheckboxName = `${name}[${mainListCheckboxIdx}]`;

        setValue(mainListCheckboxName, checkbox);
      }
    },
    [name],
  );

  return (
    <FormGroup className={classes.formGroup}>
      {listCheckboxes.map((checkbox: CheckboxRadioListItem, idx: number) => {
        const mainName = getValues('search').length > 0 ? 'filteredCheckboxes' : name;
        const checkboxName = `${mainName}[${idx}].isChecked`;
        const checkboxLevelName = `${mainName}[${idx}].level`;

        return (
          <Controller
            key={idx}
            name={checkboxName}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <CboxRadioListItem
                  onChange={(e: any) => {
                    handleChange(e, onChange, checkbox, mainName);
                  }}
                  checkboxName={checkboxName}
                  levelName={checkboxLevelName}
                  idx={idx}
                  radioOptions={radioOptions}
                  checkbox={checkbox}
                  saveFields={saveFields}
                />
              );
            }}
          />
        );
      })}
    </FormGroup>
  );
};
