import { Controller, useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormNumTextFieldStyles } from './Styles/FormNumTextFieldStyles';
import clsx from 'clsx';

interface FormInputTextProps {
  name: string;
  label: string;
  hasError?: boolean;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    '&.filledInput  .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B9A06A',
    },
    numberControl: {
      '& .MuiFormLabel-root': {
        color: '#666',
      },
      '& .MuiInputLabel-shrink': {
        color: '#999',
      },
    },
    te: {
      '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
        },
      },
      '& input': {
        fontSize: 16,
        color: '#666',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },
    },
    operationBtn: {
      height: 32,
      width: 32,
      borderRadius: 4,
      backgroundColor: '#F6F7F9',
      '& .MuiIconButton-label': {
        width: 18,
        height: 18,
      },
    },
    minusBtn: {
      marginRight: 8,
    },
    numberInput: {
      '& .MuiInputBase-input': {
        '-moz-appearance': 'textfield',
      },
      '&.filledInput  .MuiInputBase-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B9A06A',
      },
    },
  };
});

const PlusIcon: React.FC = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g stroke="none" fill="none">
      <polygon id="Path" points="0 0 20 0 20 20 0 20" />
      <polygon
        fill="#000000"
        points="15.8333333 10.8333333 10.8333333 10.8333333 10.8333333 15.8333333 9.16666667 15.8333333 9.16666667 10.8333333 4.16666667 10.8333333 4.16666667 9.16666667 9.16666667 9.16666667 9.16666667 4.16666667 10.8333333 4.16666667 10.8333333 9.16666667 15.8333333 9.16666667"></polygon>
    </g>
  </svg>
);

const MinusIcon: React.FC = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g stroke="none" fill="none">
      <polygon id="Path" points="0 0 20 0 20 20 0 20" />
      <polygon
        fill="#000000"
        points="15.8333333 10.8333333 4.16666667 10.8333333 4.16666667 9.16666667 15.8333333 9.16666667"></polygon>
    </g>
  </svg>
);

export const ForNumTextFieldStyled = FormNumTextFieldStyles(TextField);

export const FormNumberField = (props: FormInputTextProps) => {
  const classes = useStyles();
  const { name, label, hasError = false } = props;
  const { setValue, control } = useFormContext();
  const step = 0.5;
  const firstValue = 0.5;
  const textRef = useRef<any>();

  const increaseValue = useCallback(
    (value: number) => {
      if (value === 5) {
        return;
      }
      const newValue = value ? value + step : firstValue;
      setValue(name, newValue, { shouldValidate: true });
    },
    [setValue, name],
  );

  const decreaseValue = useCallback(
    (value: number | '') => {
      if (value === 0 || value === '') return;

      const newValue = value - step;
      setValue(name, newValue, { shouldValidate: true });
    },
    [setValue, name],
  );

  const focusFunc = () => {
    textRef.current.focus();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { invalid, isDirty } }) => {
        return (
          <ForNumTextFieldStyled
            error={invalid || hasError}
            inputRef={textRef}
            color={'primary'}
            onChange={onChange}
            value={value}
            label={label}
            variant={'outlined'}
            type={'number'}
            className={clsx(
              value > 0 && !invalid && !hasError ? 'filledInput' : '',
              classes.numberInput,
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={clsx(classes.operationBtn, classes.minusBtn, 'minusBtn')}
                    disabled={value === 0}
                    aria-label="decrease value"
                    onMouseDown={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      focusFunc();
                      decreaseValue(value);
                    }}
                    edge="end">
                    <MinusIcon />
                  </IconButton>

                  <IconButton
                    className={clsx(classes.operationBtn)}
                    aria-label="increase value"
                    onMouseDown={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      focusFunc();
                      increaseValue(value);
                    }}
                    edge="end">
                    <PlusIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
};
