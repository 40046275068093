import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Logo } from '../App/Shared/Logo/Logo';
import { SecondStep } from '../Domain/components/SecondStep';
import { ThirdStep } from '../Domain/components/ThirdStep';
import { FourthStep } from '../Domain/components/FourthStep/FourthStep';
import { FifthStep } from '../Domain/components/FifthStep/FifthStep';
import { SixthStep } from '../Domain/components/SixthStep';
import { DocumentsStep } from '../Domain/components/DocumentsStep';
import { FinalStep } from '../Domain/components/FinalStep';
import { Contact } from '../App/Shared/Contact/Contact';
import { FirstStep } from '../Domain/components/FirstStep';
import { FormSnackbar } from '../App/Shared/Form/FormSnackbar';
import { MobileSidebar } from '../Domain/components/Sidebar/MobileSidebar';
import { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { AppContext } from '../Context/Context';
import { CompetencesForm } from '../Domain/components/Steps/Competences';
import { TechStackForm } from '../Domain/components/Steps/TechStack';
import { IndustryKnowledgeForm } from '../Domain/components/Steps/IndustryKnowledge';
import { LanguagesForm } from '../Domain/components/Steps/Languages';
import { DetailsForm } from '../Domain/components/Steps/Details';

interface MobileStepsLayout {
  handleStep: (value: number) => void;
  step: number;
  handleCompletedStep: (value: number) => void;
  handlePretendStep: (value: number) => void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    container: {
      minHeight: '100vh',
      width: '100%',
      margin: '0 auto',
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.up('sm')]: {
        width: 480,
      },
    },
    sidebar: {
      paddingTop: 64,
    },
    bottomLogoWrapper: {
      marginBottom: 31,
    },
  };
});

export const MobileStepsLayout = (props: MobileStepsLayout): JSX.Element => {
  const { handleStep, handleCompletedStep, handlePretendStep } = props;
  const classes = useStyles();
  const [documents, setDocuments] = useState<File[]>([]);
  const { state } = useContext(AppContext);
  const {
    steps: { completedStep, step },
  } = state;

  const handleStepMiddleWare = useCallback(
    (step: number, files?: { documents: File[] }) => {
      if (files) {
        setDocuments(files.documents);
      }

      handleStep(step);
      if (completedStep < step) {
        handleCompletedStep(step);
      }
    },
    [handleStep, handleCompletedStep, setDocuments],
  );

  return (
    <Grid
      container
      className={clsx(classes.container, { [classes.sidebar]: step > 1 })}
      direction={'column'}
      spacing={2}>
      <FormSnackbar />
      {step === 1 && (
        <Grid item>
          <Logo />
        </Grid>
      )}
      {step > 0 && step < 9 && <MobileSidebar step={step} handlePretendStep={handlePretendStep} />}
      <Grid item container>
        {step === 1 && (
          <CompetencesForm
            handleStep={handleStep}
            step={step}
            handlePretendStep={handlePretendStep}
            handleCompletedStep={handleCompletedStep}
          />
        )}
        {step === 2 && (
          <TechStackForm
            handleStep={handleStep}
            step={step}
            handlePretendStep={handlePretendStep}
            handleCompletedStep={handleCompletedStep}
          />
        )}
        {step === 3 && (
          <IndustryKnowledgeForm
            handleStep={handleStep}
            step={step}
            handlePretendStep={handlePretendStep}
            handleCompletedStep={handleCompletedStep}
          />
        )}
        {step === 4 && (
          <LanguagesForm
            handleStep={handleStep}
            step={step}
            handlePretendStep={handlePretendStep}
            handleCompletedStep={handleCompletedStep}
          />
        )}
        {step === 5 && (
          <DetailsForm handleStep={handleStep} step={step} handlePretendStep={handlePretendStep} />
        )}
        {/*{step === 2 && (*/}
        {/*  <SecondStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 3 && (*/}
        {/*  <ThirdStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 4 && (*/}
        {/*  <FourthStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 5 && (*/}
        {/*  <FifthStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 6 && (*/}
        {/*  <SixthStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 7 && (*/}
        {/*  <DocumentsStep*/}
        {/*    handleStep={handleStepMiddleWare}*/}
        {/*    step={step}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*    handleCompletedStep={handleCompletedStep}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{step === 8 && (*/}
        {/*  <FinalStep*/}
        {/*    handleStep={handleStep}*/}
        {/*    step={step}*/}
        {/*    documents={documents}*/}
        {/*    handlePretendStep={handlePretendStep}*/}
        {/*  />*/}
        {/*)}*/}
      </Grid>
    </Grid>
  );
};
