import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const ContactForBubbleStyles = makeStyles<Theme>(() => {
  return {
    root: {
      display: 'column',
    },
    wrapperInfo: {
      display: 'flex',
    },
    emailWrapper: {
      marginBottom: 8,
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    contactText: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#32AFE4',
    },
  };
});