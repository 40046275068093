import * as Yup from 'yup';
import { GroupOpt } from '../../Types/FormFields';

export interface CheckboxWithRadio {
  value: string;
  isChecked: boolean;
  level: string;
}

export const CheckboxRadioSchema = (name: string, errorMessage: string, formatData?: string) =>
  Yup.object().shape({
    [name]: Yup.array(
      Yup.object({
        value: Yup.string(),
        isChecked: Yup.boolean(),
        level: Yup.string() || Yup.number(),
      }).test(`${name}Test`, errorMessage, (data: any, context: any) => {
        const { parent } = context;

        let checkboxesList = [];

        if (formatData === 'groupsData') {
          checkboxesList = parent.map((group: GroupOpt) => group.entries).flat();
        } else {
          checkboxesList = parent;
        }
        const checkedCheckboxes = checkboxesList.filter(
          (checkbox: CheckboxWithRadio) => checkbox.isChecked,
        );

        if (name === 'techsGroupsOpt') {
          if (checkedCheckboxes.length > 0) {
            return !checkedCheckboxes.find(
              (checkbox: CheckboxWithRadio) => checkbox.level.length === 0,
            );
          } else {
            return true;
          }
        } else {
          if (checkedCheckboxes.length > 0) {
            return !checkedCheckboxes.find(
              (checkbox: CheckboxWithRadio) => checkbox.level.length === 0,
            );
          }
        }

        return false;
      }),
    ),
  });
