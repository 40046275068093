import { StepsProgressBar } from '../StepsProgressBar';
import { Box, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '../../../Icons/Check.svg';
import { SidebarProps } from './Sidebar';
import { Theme } from '@material-ui/core/styles';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 100,
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      '& li': {
        backgroundColor: '#FFF',
        borderRight: '0.5px solid #EFEFEF',
        borderBottom: '0.5px solid #EFEFEF',
        padding: '13px 0',
      },
      '& .MuiListItemText-root': {
        margin: 0,
        '& .MuiTypography-root': {
          color: '#999',
          fontSize: 16,
          lineHeight: '24px',
          textAlign: 'center',
        },
      },
    },
    activeListItem: {
      backgroundColor: '#FAFAFA',
      '& .MuiListItemText-root .MuiTypography-root': {
        color: '#000',
        fontWeight: 500,
      },
    },
  };
});

export const MobileSidebar = (props: SidebarProps) => {
  const { handlePretendStep } = props;
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const {
    steps: { step, completedStep },
  } = state;

  return (
    <Box className={classes.root}>
      <List className={classes.list}>
        <ListItem
          onTouchEnd={
            completedStep >= 1 || completedStep + 1 === 1 ? () => handlePretendStep(1) : undefined
          }
          className={clsx({
            [classes.activeListItem]: step === 1,
            [classes.hover]: completedStep >= 1 || completedStep + 1 === 1,
          })}>
          <ListItemText>
            <span className={classes.prefixListItemText}>
              {completedStep >= 1 ? <img src={CheckIcon} /> : '1'}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem
          onTouchEnd={
            completedStep >= 2 || completedStep + 1 === 2 ? () => handlePretendStep(2) : undefined
          }
          className={clsx({
            [classes.activeListItem]: step === 2,
          })}>
          <ListItemText>
            <span className={classes.prefixListItemText}>
              {completedStep >= 2 ? <img src={CheckIcon} /> : '2'}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem
          onTouchEnd={
            completedStep >= 3 || completedStep + 1 === 3 ? () => handlePretendStep(3) : undefined
          }
          className={clsx({
            [classes.activeListItem]: step === 3,
          })}>
          <ListItemText>
            <span className={classes.prefixListItemText}>
              {completedStep >= 3 ? <img src={CheckIcon} /> : '3'}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem
          onTouchEnd={
            completedStep >= 4 || completedStep + 1 === 4 ? () => handlePretendStep(4) : undefined
          }
          className={clsx({
            [classes.activeListItem]: step === 4,
          })}>
          <ListItemText>
            <span className={classes.prefixListItemText}>
              {completedStep >= 4 ? <img src={CheckIcon} /> : '4'}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem
          onTouchEnd={
            completedStep >= 5 || completedStep + 1 === 5 ? () => handlePretendStep(5) : undefined
          }
          className={clsx({
            [classes.activeListItem]: step === 5,
          })}>
          <ListItemText>
            <span className={classes.prefixListItemText}>
              {completedStep >= 5 ? <img src={CheckIcon} /> : '5'}
            </span>
          </ListItemText>
        </ListItem>
        {/*<ListItem*/}
        {/*  onTouchEnd={*/}
        {/*    completedStep >= 6 || completedStep + 1 === 6 ? () => handlePretendStep(6) : undefined*/}
        {/*  }*/}
        {/*  className={clsx({*/}
        {/*    [classes.activeListItem]: step === 6,*/}
        {/*  })}>*/}
        {/*  <ListItemText>*/}
        {/*    <span className={classes.prefixListItemText}>*/}
        {/*      {completedStep >= 6 ? <img src={CheckIcon} /> : '6'}*/}
        {/*    </span>*/}
        {/*  </ListItemText>*/}
        {/*</ListItem>*/}
        {/*<ListItem*/}
        {/*  onTouchEnd={*/}
        {/*    completedStep >= 7 || completedStep + 1 === 7 ? () => handlePretendStep(7) : undefined*/}
        {/*  }*/}
        {/*  className={clsx({*/}
        {/*    [classes.activeListItem]: step === 7,*/}
        {/*  })}>*/}
        {/*  <ListItemText>*/}
        {/*    <span className={classes.prefixListItemText}>*/}
        {/*      {completedStep >= 7 ? <img src={CheckIcon} /> : '7'}*/}
        {/*    </span>*/}
        {/*  </ListItemText>*/}
        {/*</ListItem>*/}
      </List>
      <StepsProgressBar step={step} />
    </Box>
  );
};
