import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { AppContext } from '../../../../Context/Context';
import { FormProvider, useForm } from 'react-hook-form';
import { StepsTypes } from '../../../../Context/StepsReducer';
import { CheckboxGroup } from '../../../../App/Shared/Form/CheckboxGroup';
import { SearchTextField } from '../../../../App/Shared/Form/Customized/SearchTextField';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { CheckboxListItemApi, RegDataTypes } from '../../../../Types/ResponseType';
import { useFormSnackbar } from '../../../../Hooks/useFormSnackbar';
import { IndustryKnowledgeModel } from './interface';

interface IndustryKnowledgeFormProps {
  handleStep: (value: number) => void;
  step: number;
  handlePretendStep: (value: number) => void;
  handleCompletedStep: (value: number) => void;
}

export const IndustryKnowledgeForm = (props: IndustryKnowledgeFormProps): JSX.Element => {
  const classes = StepFormStyles();
  const { handleStep, step, handlePretendStep, handleCompletedStep } = props;

  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const { fields, regData, pretendStep, completedStep } = steps;
  const { modules } = regData as RegDataTypes;
  const handleFormSnackbar = useFormSnackbar();

  const methods = useForm<IndustryKnowledgeModel>({
    defaultValues: {
      checkboxesList: modules.sectors,
      sectors: fields.sectors,
      search: '',
    },
    mode: 'all',
  });

  methods.watch();

  const saveFields = useCallback(() => {
    const stepFormFields = methods.getValues('sectors');
    const newFields = { ...fields, sectors: stepFormFields };

    dispatch({
      type: StepsTypes.SET_FIELDS,
      payload: {
        fields: {
          ...newFields,
        },
      },
    });
  }, [fields, dispatch]);

  const onSubmitPrevious = (data: any) => {
    saveFields();
    const newStep = step - 1;
    handleStep(newStep);
  };

  const handlePreviousStep = useCallback(() => {
    methods.handleSubmit(onSubmitPrevious)();
  }, []);

  const onSubmit = (data: any) => {
    const newStep = step + 1;

    saveFields();
    handleStep(newStep);

    if (completedStep < step) {
      handleCompletedStep(step);
    }
  };

  const handleChangeSearch = useCallback(() => {
    const searchValue = methods.getValues('search').toLowerCase();

    const checkboxesList = modules.sectors.filter((sector: CheckboxListItemApi) =>
      sector.label.toLowerCase().includes(searchValue),
    );

    methods.setValue('checkboxesList', checkboxesList);
  }, [methods]);

  useEffect(() => {
    const errorsL = Object.entries(methods.formState.errors).length;

    if (methods.formState.isSubmitted && errorsL > 0) {
      handleFormSnackbar(true, 'Bitte wähle mindestens zwei Branchenexpertisen aus.');
    }
  }, [methods.formState.submitCount]);

  const onSubmitOutside = (data: any) => {
    saveFields();
    handleStep(pretendStep);
  };

  useEffect(() => {
    if (pretendStep !== 0) {
      methods.handleSubmit(onSubmitOutside)();
      handlePretendStep(0);
    }
  }, [pretendStep]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
        <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>
          <FormHeader label={'Branchenexpertise'} />
          <Typography variant={'body2'} className={classes.subHeader}>
            Wähle die Branchen aus, in denen Du bereits Projekterfahrung gesammelt hast.
          </Typography>
          <Grid container direction={'column'} className={classes.fieldsWrapper}>
            <Grid item className={classes.searchWrapper}>
              <SearchTextField
                name={'search'}
                placeholder={'z. B. „E-Commerce“'}
                filterHandleChange={handleChangeSearch}
              />
            </Grid>

            <Grid item className={classes.item}>
              <CheckboxGroup name={'sectors'} options={methods.getValues('checkboxesList')} />
            </Grid>
          </Grid>
          <Button className={classes.nextBtn} type={'submit'}>
            <Box className={classes.btnContentWrapper}>
              Weiter
              {methods.getValues('sectors').length > 0 && (
                <Box className={classes.checkedNumber}>{methods.getValues('sectors').length}</Box>
              )}
            </Box>
          </Button>
          <Button
            className={classes.backBtn}
            color={'primary'}
            variant={'outlined'}
            onClick={handlePreviousStep}>
            Zurück
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
