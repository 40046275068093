import { createStyles, withStyles } from '@material-ui/core';

export const FormRadioStyles = withStyles((theme) =>
  createStyles({
    root: {
      padding: 0,
      color: '#ccc',
      marginRight: 12,

    },
  }),
);