import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const StepFormStyles = makeStyles<Theme>(theme => {
  return {
    form: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    root: {
      marginLeft: 72,
      marginRight: 72,
      marginTop: 16,
      marginBottom: 0,
      [theme.breakpoints.down('md')]: {
        marginTop: 8,
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    subHeader: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.primary.main,
      marginBottom: 36,
      marginTop: 24,
    },
    fieldsWrapper: {
      marginTop: 35,
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
      },
    },
    nextBtn: {
      height: 60,
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'unset',
      marginBottom: 10,
      width: '100%',
      '&:hover': {
        backgroundColor: '#0C96D4',
      },
      fontFamily: 'Maven Pro Bold',
    },
    btnContentWrapper: {
      display: 'flex',
    },
    checkedNumber: {
      borderRadius: 4,
      marginLeft: 12,
      color: '#fff',
      fontSize: 12,
      textAlign: 'center',
      width: 24,
      fontWeight: 500,
      backgroundColor: '#288CB6',
    },
    backBtn: {
      height: 60,
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '18px',
      textTransform: 'unset',
      marginBottom: 12,
    },
    divider: {
      marginTop: 16,
    },
    searchWrapper: {
      width: '100%',
    },
    item: {
      width: '100%',
    },
    header: {
      marginTop: 8,
    },
    vertCenter: {
      marginTop: '50%',
      transform: 'translateY(-50%)',
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
        transform: 'translateY(0)',
      },
    },
    selectionWrapper: {
      position: 'relative',
      marginBottom: 12,
      // height: '60vh',
    },
    opacityDisabled: {
      opacity: 0.07,
      pointer: 'none',
    },
  };
});
