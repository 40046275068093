import { makeStyles, Theme } from '@material-ui/core/styles';

export const DropFieldStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      marginBottom: 0,
    },
    header: {
      color: '#000000',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: 18,
    },
    uploadedFile: {
      padding: '14px 12px',
      border: '1px solid #B9A06A',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
      color: '#000',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
    },
    oneOfUploadedFile: {
      marginBottom: 12,
    },
    clearUploadedFile: {
      padding: 0,
      '& img': {
        height: 17,
        width: 17,
      },
    },
    supportedFilesNames: {
      marginTop: 8,
      fontSize: 12,
      lineHeight: '16px',
      textAlign: 'center',
      color: '#999999',
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
  };
});
