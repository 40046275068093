import { CheckboxListItem, EntryOpt } from '../../Types/FormFields';


export const isCheckboxChecked = (checkedCheckboxes: CheckboxListItem[], checkbox: CheckboxListItem) => {
  return checkedCheckboxes.some((checkedCheckbox: CheckboxListItem) => {

    if (checkbox.groupLabel) {
      return checkedCheckbox.id === checkbox.id && checkedCheckbox.groupLabel === checkbox.groupLabel;
    }

    return checkedCheckbox.id === checkbox.id;
  });
};

export const getCheckboxesAfterDelete = (checkedCheckboxes: CheckboxListItem[], itemToDelete: CheckboxListItem) => {
  return (
    checkedCheckboxes.filter((listItem: CheckboxListItem) => {
      if (listItem.groupLabel) {
        if (listItem.id === itemToDelete.id) {
          return listItem.groupLabel !== itemToDelete.groupLabel;
        } else return listItem.id !== itemToDelete.id;
      } else return listItem.id !== itemToDelete.id;
    })
  );
};

export const getEntriesWithGrpLabel = ((entries: EntryOpt[] | CheckboxListItem[], groupLabel: string) => (
  entries.map(entry => ({ ...entry, groupLabel: groupLabel }))
));
