import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useCallback } from 'react';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      margin: 72,
      marginTop: 300,
      marginBottom: 0,
    },
    header: {
      color: '#000',
      fontSize: 36,
      fontWeight: 'bold',
      lineHeight: '48px',
      marginBottom: 36,
      fontFamily: 'Maven Pro Bold',
    },
    subHeader: {
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.primary.main,
      marginBottom: 36,
    },
    cta: {
      width: 327,
      borderRadius: 4,
      borderWidth: 2,
      padding: '21px 0',
      textTransform: 'unset',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '18px',
      '&:hover': {
        borderWidth: 2,
      },
    },
  };
});

export const SuccessSubmit = (): JSX.Element => {
  const classes = useStyles();

  const handleRedirect = useCallback(() => {
    window.location.href = 'https://biznetwork.bizforward.de/login';
  }, []);

  return (
    <Grid className={classes.root} container direction={'column'} item xs={7}>
      <Typography variant={'body2'} className={classes.header}>
        Expertenprofil fertig ausgefüllt!
      </Typography>

      <Typography variant={'body2'} className={classes.subHeader}>
        Du hast eine Bestätigungsmail erhalten. <br />
        <br />
        Wir melden uns bei Dir, sobald wir passende Projekte für Dich vorliegen haben!
      </Typography>

      <Button
        className={classes.cta}
        variant={'outlined'}
        color={'secondary'}
        onClick={handleRedirect}>
        Freelancer Login
      </Button>
    </Grid>
  );
};
