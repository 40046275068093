import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useCallback } from 'react';

const useStyles = makeStyles<Theme>(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    wrapperImg: {
      height: 216,
      backgroundColor: '#F8F8F8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 280,
        height: 280,
      },
    },
    header: {
      color: '#000',
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: '36px',
      marginBottom: 36,
      margin: 24,
    },
    subHeader: {
      fontSize: 16,
      lineHeight: '24px',
      color: theme.palette.primary.main,
      marginBottom: 36,
      margin: 24,
      fontFamily: 'Maven Pro Bold',
    },
    ctaWrapper: {
      paddingLeft: 24,
      paddingRight: 24,
      width: '100%',
    },
    cta: {
      width: '100%',
      borderRadius: 4,
      borderWidth: 2,
      padding: '21px 0',
      textTransform: 'unset',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '18px',
      '&:hover': {
        borderWidth: 2,
      },
    },
  };
});

export const MobileSuccessSubmit = (): JSX.Element => {
  const classes = useStyles();

  const handleRedirect = useCallback(() => {
    window.location.href = 'https://biznetwork.bizforward.de/login';
  }, []);

  return (
    <Grid>
      <Box className={classes.wrapperImg}>
        <svg width="576px" height="576px" viewBox="0 0 576 576">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="7.-Confirmation" transform="translate(-72.000000, -177.000000)">
              <g id="Grafik" transform="translate(72.000000, 177.000000)">
                <rect id="Bound" x="0" y="0" width="576" height="576"></rect>
                <g
                  id="rocket"
                  transform="translate(217.500000, 168.000000)"
                  stroke="#000000"
                  strokeLinecap="round">
                  <path
                    d="M33.1475627,66.0389738 C33.1475627,32.7433239 68.9940256,0 68.9940256,0 C68.9940256,0 104.840489,32.6592743 104.840489,66.0389738 L104.840489,166.202086 L33.1475627,166.202086 L33.1475627,66.0389738 Z"
                    id="Path"
                    strokeWidth="7.2"
                    strokeLinejoin="round"></path>
                  <polygon
                    strokeWidth="7.2"
                    strokeLinejoin="round"
                    points="43.9015015 166.190079 39.1219731 183 98.866078 183 94.0865496 166.190079"></polygon>
                  <polygon
                    strokeWidth="7.2"
                    strokeLinejoin="round"
                    points="33.1475627 166.190079 12.5 183 12.5 127.491241 33.1475627 99.2745883"></polygon>
                  <polygon
                    strokeWidth="7.2"
                    strokeLinejoin="round"
                    points="104.840489 166.190079 125.5 183 125.5 127.491241 104.840489 99.2745883"></polygon>
                  <ellipse
                    strokeWidth="7.2"
                    fill="#32AFE4"
                    fillRule="nonzero"
                    strokeLinejoin="round"
                    cx="68.9940256"
                    cy="87.7597927"
                    rx="17.3257904"
                    ry="17.4102749"></ellipse>
                  <path
                    d="M92.5690494,28.9370776 C79.4468567,41.7477278 58.5650921,41.7477278 45.4428994,28.9370776"
                    id="Path"
                    strokeWidth="7.2"
                    strokeLinejoin="round"></path>
                  <line x1="0.5" y1="174" x2="0.5" y2="205" id="Path-2" strokeWidth="3"></line>
                  <path
                    d="M92.0445179,3.85760609 C104.446142,18.4626394 112.62493,33.2926718 116.580882,48.3477033"
                    id="Path-3"
                    strokeWidth="3"></path>
                </g>
                <g id="drive" transform="translate(271.000000, 360.000000)" fill="#32AFE4">
                  <rect y="0" width="8" height="43" rx="4"></rect>
                  <rect x="28" y="0" width="8" height="14" rx="4"></rect>
                  <rect x="0" y="0" width="8" height="19" rx="4"></rect>
                  <rect x="0" y="20" width="8" height="8" rx="4"></rect>
                  <rect x="28" y="15" width="8" height="8" rx="4"></rect>
                  <rect x="0" y="30" width="8" height="19" rx="4"></rect>
                  <rect x="28" y="25" width="8" height="14" rx="4"></rect>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Box>
      <Grid className={classes.container}>
        <Typography variant={'body2'} className={classes.header}>
          Expertenprofil fertig ausgefüllt!
        </Typography>

        <Typography variant={'body2'} className={classes.subHeader}>
          Wir melden uns bei Dir, sobald wir passende Projekte für Dich vorliegen haben!
        </Typography>

        <Box className={classes.ctaWrapper}>
          <Button
            className={classes.cta}
            variant={'outlined'}
            color={'secondary'}
            onClick={handleRedirect}>
            Freelancer Login
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
