import React from 'react';
import { Box, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>(theme => {
  return {
    radioGroupWrapperBorder: {
      position: 'relative',
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 12,
      marginTop: 14,
      '&::before': {
        content: "''",
        position: 'absolute',
        left: -10,
        width: 'calc(100% + 10px)',
        height: 'calc(100%)',
        borderRadius: 8,
        border: '2px solid #EFEFEF',
        [theme.breakpoints.down('sm')]: {
          backgroundColor: '#F8F8F8',
          border: 'unset',
        },
      },
    },
    radioGroupSpeechBubble: {
      position: 'relative',
      paddingTop: 8,
      paddingBottom: 8,
      '&::before': {
        content: "''",
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '14px solid transparent',
        borderBottom: `14px solid #EFEFEF`,
        left: 0,
        top: -28,
        [theme.breakpoints.down('sm')]: {
          borderBottom: `14px solid #F8F8F8`,
        },
      },

      '&::after': {
        content: "''",
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '14px solid transparent',
        borderBottom: '14px solid #fff',
        left: 0,
        top: -24,
        [theme.breakpoints.down('sm')]: {
          borderBottom: `14px solid #F8F8F8`,
        },
      },
      label: {
        fontSize: 16,
        color: theme.palette.primary.main,
        padding: '10px 0',
        margin: 0,
        borderBottom: '1px solid #EFEFEF',
        '&:last-child': {
          borderBottom: 'unset',
        },
        '& .MuiIconButton-root': {
          padding: 0,
          marginRight: 12,
        },
      },
    },
    radio: {
      color: '#ccc',
      marginLeft: 8,
      marginRight: 8,
    },
    // levelWrapper: {
    //   paddingBottom: 10,
    //   borderBottom: '1px solid #EFEFEF',
    //   '&:last-child': {
    //     borderBottom: 'unset',
    //   },
    // },
    // levelBtn: {
    //   textTransform: 'unset',
    //   padding: '0 5px',
    //   color: '#999',
    //   fontSize: 16,
    //   lineHeight: '20px',
    //   marginLeft: 27,
    // },
  };
});

export const techStackOptions = [
  { label: 'Grundkenntnisse', id: '1' },
  { label: 'Erweiterte Kenntnisse', id: '2' },
  { label: 'Sehr gute Kenntnisse', id: '3' },
  { label: 'Expertenlevel', id: '4' },
];

interface SelectionRadioProps {
  handleUpdate(value: string): void;
  withWrapper?: boolean;
}
export const SelectionRadio: React.FC<SelectionRadioProps> = ({
  handleUpdate,
  withWrapper = true,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.radioGroupWrapperBorder}>
      <Box className={classes.radioGroupSpeechBubble}>
        <RadioGroup>
          {techStackOptions.map(option => (
            <FormControlLabel
              className={clsx(classes.label)}
              key={option.id}
              control={<Radio value={option.id} className={classes.radio} />}
              label={option.label}
              onChange={() => {
                handleUpdate(option.id);
              }}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};
