import TextField from '@material-ui/core/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { FormTextFieldStyles } from './Styles/FormTextFieldStyles';
import Info from '../../../Icons/Info.svg';
import { CustomTooltip } from './Tooltip/CustomTooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface FormInputTextProps {
  name: string;
  label: string;
  type?: string;
  tooltipTitle?: string;
  placeholder?: string;
  multiline?: boolean;
  validateOnBlur?: () => void;
  hasError?: boolean;
}

export const FormTextFieldStyled = FormTextFieldStyles(TextField);

export const FormTextField = (props: FormInputTextProps) => {
  const {
    name,
    label,
    type = 'text',
    tooltipTitle,
    placeholder,
    multiline = false,
    validateOnBlur,
    hasError = false,
  } = props;
  const { control } = useFormContext();
  const mobileView = useMediaQuery('(max-width:1024px)');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
        return (
          <>
            <FormTextFieldStyled
              error={invalid || hasError}
              color={'secondary'}
              onChange={onChange}
              value={value}
              multiline={multiline}
              label={label}
              placeholder={placeholder ? placeholder : undefined}
              variant={'outlined'}
              type={type}
              className={value.length > 0 && !invalid && !hasError ? 'filledInput' : ''}
              onBlur={validateOnBlur}
              InputProps={{
                endAdornment: tooltipTitle ? (
                  <CustomTooltip
                    leaveTouchDelay={2500}
                    enterTouchDelay={1}
                    title={tooltipTitle}
                    arrow={!mobileView}
                    placement={mobileView ? 'bottom-end' : 'right'}>
                    <img alt={''} src={Info} className={'tooltipIcon'} />
                  </CustomTooltip>
                ) : null,
              }}
            />
          </>
        );
      }}
    />
  );
};
