import { makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface FormHeaderProps {
  label: string
}

const useStyles = makeStyles<Theme>((theme) => {
  return {
    header: {
      color: '#000',
      fontSize: 36,
      lineHeight: '48px',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
        lineHeight: '36px'
      },
      fontFamily: 'Maven Pro Bold'
    },
  };
});

export const FormHeader = (props: FormHeaderProps): JSX.Element => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Typography variant={'body1'} className={classes.header}>
      {label}
    </Typography>
  );
};
