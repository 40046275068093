import { Box, createStyles, LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface StepsProgressBarProps {
  step: number;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    progressBarWrapper: {
      transform: 'rotate(90deg)',
      transformOrigin: 0,
      width: 198,
      [theme.breakpoints.down('sm')]: {
        transform: 'unset',
        width: '100%',
        position: 'absolute',
        bottom: 0,
      },
    },
  };
});

export const StepsProgressBarStyled = withStyles(theme =>
  createStyles({
    root: {
      width: 206,
      height: 2,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    colorPrimary: {
      backgroundColor: '#EFEFEF',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
    },
    bar: {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
)(LinearProgress);

export const StepsProgressBar = (props: StepsProgressBarProps): JSX.Element => {
  const { step } = props;
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');

  const totalProgress = 100;
  const totalSteps = 4;
  const oneStepValue = totalProgress / totalSteps;

  const progressValue = useMemo(() => {
    const pastStep = step - 1;
    if (!mobileView) {
      return oneStepValue * pastStep;
    } else {
      return oneStepValue * pastStep + oneStepValue * 0.5;
    }
  }, [step]);

  return (
    <Box className={classes.progressBarWrapper}>
      <StepsProgressBarStyled
        variant="determinate"
        value={progressValue > 100 ? 100 : progressValue}
      />
    </Box>
  );
};
