import React from 'react';

export const IconAddItem: React.FC = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <circle fill="#32AFE4" cx="10" cy="10" r="10" />
    <g transform="translate(4.000000, 4.000000)">
      <polygon
        fill="#FFFFFF"
        transform="translate(6.000000, 6.000000) rotate(-315.000000) translate(-6.000000, -6.000000) "
        points="9.5 3.205 8.795 2.5 6 5.295 3.205 2.5 2.5 3.205 5.295 6 2.5 8.795 3.205 9.5 6 6.705 8.795 9.5 9.5 8.795 6.705 6"
      />
    </g>
  </svg>
);
