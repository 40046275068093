import * as Yup from 'yup';

export const ShortRegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required('').min(3),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], '')
    .required(''),
  uploadedGenDocs: Yup.array(),
  profileUrl: Yup.string().required(),
  isGdpr: Yup.boolean().oneOf([true]).required(),
  isFreelancer: Yup.boolean().oneOf([true]).required(),
});
