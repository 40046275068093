import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { LinkResponse, RegDataTypes } from '../../Types/ResponseType';
import { RegDataPutTypes, ShortRegData } from '../../Types/RegDataPut';

export const getAxiosWithConfig = (): AxiosInstance => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}api/signup`,
    timeout: 30000,
    headers: {
      'X-Auth-Token': `b+Ez-Wr]*"l]$h\\4LO*e`,
    },
  });
};

export const getAxiosWithUploadConfig = (): AxiosInstance => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}api/signup`,
    timeout: 30000,
    headers: {
      'X-Auth-Token': `b+Ez-Wr]*"l]$h\\4LO*e`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getRegistrationData = async (): Promise<AxiosResponse<RegDataTypes>> => {
  try {
    const instance = getAxiosWithConfig();
    return instance.get('/registration');
  } catch (e: any) {
    throw new Error(e);
  }
};

export const uploadFiles = async (signupId: number, files: any): Promise<AxiosResponse<any>> => {
  try {
    const instance = getAxiosWithUploadConfig();
    const formData = new FormData();

    files.map((file: File) => {
      formData.append('files[]', file);
    });

    return instance.post('/files/' + signupId, formData);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const putShortRegistration = async (data: ShortRegData): Promise<AxiosResponse<any>> => {
  try {
    const instance = getAxiosWithConfig();
    return instance.put('/registration-short', data);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const addRegData = async (data: any): Promise<AxiosResponse<any>> => {
  try {
    const instance = getAxiosWithConfig();
    return instance.put('/registration-long', data);
  } catch (e: any) {
    throw new Error(e);
  }
};

export const checkLink = async (link: string): Promise<AxiosResponse<LinkResponse>> => {
  try {
    const instance = getAxiosWithConfig();
    return instance.post('/linkcheck', { link });
  } catch (e: any) {
    throw new Error(e);
  }
};
