import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, makeStyles, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import { FormTextFieldStyled } from '../../../App/Shared/Form/FormDropdown';
import { SelectionRadio, techStackOptions } from '../GroupItemsSelection/SelectionRadio';
import { Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import clsx from 'clsx';
import { HandleUpdateParams } from '../Steps/TechStack';

interface SelectionProps {
  group: string;
  defaultGroup: string[];
  addItem(value: HandleUpdateParams): void;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    wrapper: {
      marginTop: 12,
    },
    cta: {
      marginTop: 12,
      textTransform: 'unset',
      fontSize: 16,
      lineHeight: '18px',
      fontWeight: 500,
      paddingTop: 15,
      paddingBottom: 15,
      '&:hover': {
        borderWidth: 2,
      },
    },
  };
});

export const Selection: React.FC<SelectionProps> = ({ group, defaultGroup, addItem }) => {
  const [groupValue, setGroupValue] = useState(group);
  const [level, setLevel] = useState('');
  const [disabled, setDisabled] = useState(true);
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupValue(e.target.value);
  };

  const radioUpdate = (value: string) => {
    setLevel(value);
    console.log(value);
  };

  const handleSubmit = () => {
    if (!(!groupValue || !level)) {
      addItem({ parent: groupValue, value: level, id: '' });
    }
  };

  useEffect(() => {
    setDisabled(!groupValue || !level);
  }, [groupValue, level]);

  return (
    <div className={classes.wrapper}>
      <FormTextFieldStyled
        color="secondary"
        onChange={handleChange}
        value={groupValue}
        select
        label="Kategorie"
        placeholder={groupValue}
        variant="outlined"
        type="text">
        {defaultGroup.map(group => (
          <MenuItem key={group} value={group} id={group}>
            {group}
          </MenuItem>
        ))}
      </FormTextFieldStyled>
      <RadioGroup>
        {techStackOptions.map(option => (
          <FormControlLabel
            className={clsx(classes.label)}
            key={option.id}
            control={<Radio value={option.id} className={classes.radio} />}
            label={option.label}
            onChange={() => {
              radioUpdate(option.id);
            }}
          />
        ))}
      </RadioGroup>
      <Button
        fullWidth
        onClick={handleSubmit}
        disabled={disabled}
        variant="outlined"
        color="secondary"
        className={classes.cta}>
        Hinzufügen
      </Button>
    </div>
  );
};
