import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { IconArrowExpand } from '../../../Icons/IconArrowExpand';

const useStyles = makeStyles<Theme>(theme => {
  return {
    modalBtn: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'unset',
      justifyContent: 'space-between',
      color: '#000',
      padding: '12px 0',
      width: '100%',
      cursor: 'pointer',
      borderBottom: '1px solid #EFEFEF',
      '& .MuiInputBase-root.Mui-disabled': {
        cursor: 'unset',
      },
      '& input': {
        cursor: 'pointer',
      },
    },
    lastModalBtn: {
      borderBottom: 'unset',
    },
    btnContentWrapper: {
      display: 'flex',
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'left',
        paddingLeft: 8,
      },
    },
    checkedNumber: {
      borderRadius: 4,
      marginLeft: 12,
      color: '#fff',
      fontSize: 12,
      textAlign: 'center',
      minWidth: 24,
      height: 24,
      fontWeight: 500,
      backgroundColor: theme.palette.secondary.main,
    },
  };
});

interface GroupSelectionProps {
  onGroupSelection(group: string): void;
  label: string;
  selectionNumber: number;
}
export const GroupSelection: React.FC<GroupSelectionProps> = ({
  label,
  onGroupSelection,
  selectionNumber,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    onGroupSelection(label);
  };

  return (
    <Button className={classes.modalBtn} onClick={handleClick}>
      <Box className={classes.btnContentWrapper}>
        <Typography variant={'body2'}>{label}</Typography>
        {selectionNumber > 0 && <Box className={classes.checkedNumber}>{selectionNumber}</Box>}
      </Box>
      <IconArrowExpand />
    </Button>
  );
};
