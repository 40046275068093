import { Box, List, ListItem, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Menu from '@material-ui/icons/Menu';
import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { CheckboxListItemApi } from '../../../../Types/ResponseType';
import { CheckboxListItem } from '../../../../Types/FormFields';

interface SortCompetencesListProps {
  name: string;
}

interface SortableListProps {
  checkedCompetencesList: CheckboxListItem[];
}

const useDragStyles = makeStyles<Theme>(() => {
  return {
    grab: {
      height: 24,
      color: '#ccc',
      '&:hover': {
        cursor: 'grab',
      },
    },
  };
});

const useSortableListStyles = makeStyles<Theme>(() => {
  return {};
});

const useSortableItemStyles = makeStyles<Theme>(theme => {
  return {
    listItem: {
      padding: '12px 0',
      borderBottom: '1px solid #EFEFEF',
      display: 'flex',
      justifyContent: 'space-between',
    },
    priority: {
      fontSize: 12,
      borderRadius: '50%',
      height: 20,
      flex: '20px 0 0',
      color: '#fff',
      textAlign: 'center',
      marginRight: 14,
    },
    priority1: {
      backgroundColor: theme.palette.secondary.main,
    },
    priority2: {
      backgroundColor: '#288CB6',
    },
    priority3: {
      backgroundColor: '#227A9F',
    },
    priority4: {
      backgroundColor: '#1E6988',
    },
    priority5: {
      backgroundColor: '#185771',
    },
    textWrapper: {
      display: 'flex',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#000',
    },
  };
});

const DragHandle = SortableHandle(() => {
  const classes = useDragStyles();

  return (
    <span>
      <Menu className={classes.grab} />
    </span>
  );
});

export interface SortableItemProps {
  idx: number;
  checkedListItem: CheckboxListItemApi;
  dragComponent: ReactElement<any, any>;
}

const SortableItem = SortableElement(
  ({ idx, dragComponent, checkedListItem }: SortableItemProps) => {
    const classes = useSortableItemStyles();

    const priorityNumber = idx + 1;

    return (
      <ListItem className={classes.listItem}>
        <Box className={classes.textWrapper}>
          <Box
            className={clsx(classes.priority, {
              [classes.priority1]: priorityNumber === 1,
              [classes.priority2]: priorityNumber === 2,
              [classes.priority3]: priorityNumber === 3,
              [classes.priority4]: priorityNumber === 4,
              [classes.priority5]: priorityNumber === 5,
            })}>
            {priorityNumber}
          </Box>
          {checkedListItem.label}
        </Box>
        {dragComponent}
      </ListItem>
    );
  },
);

const SortableList = SortableContainer(({ checkedCompetencesList }: SortableListProps) => {
  const classes = useSortableListStyles();

  return (
    <List className={classes.list}>
      {checkedCompetencesList.map((checkedListItem, index: number) => {
        return (
          <SortableItem
            idx={index}
            key={index}
            checkedListItem={checkedListItem}
            index={index}
            dragComponent={<DragHandle />}
          />
        );
      })}
    </List>
  );
});

export const SortCompetencesList = (props: SortCompetencesListProps): JSX.Element => {
  const { name } = props;

  const { setValue, watch } = useFormContext();
  const checkedCompetences = watch(name);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newArray = arrayMove(checkedCompetences, oldIndex, newIndex);

    setValue(name, newArray);
  };

  return (
    <SortableList
      checkedCompetencesList={checkedCompetences}
      useDragHandle
      axis="y"
      onSortEnd={onSortEnd}
    />
  );
};
