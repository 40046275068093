import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppContext } from '../../../../Context/Context';
import { StepsTypes } from '../../../../Context/StepsReducer';
import { competenceSchema } from './schema';
import { Competences } from './Competences';
import { SortCompetences } from './SortCompetences';
import { RegDataTypes } from '../../../../Types/ResponseType';
import { useFormSnackbar } from '../../../../Hooks/useFormSnackbar';
import { CompetenceModel } from './interface';

interface CompetencesFormProps {
  handleStep: (value: number) => void;
  step: number;
  handlePretendStep: (value: number) => void;
  handleCompletedStep: (value: number) => void;
}

export const CompetencesForm = (props: CompetencesFormProps): JSX.Element => {
  const { handleStep, step, handleCompletedStep, handlePretendStep } = props;
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const { fields, regData, pretendStep } = steps;
  const { modules } = regData as RegDataTypes;
  const handleFormSnackbar = useFormSnackbar();

  const methods = useForm<CompetenceModel>({
    defaultValues: {
      competences: fields.competences.length > 0 ? fields.competences : [],
      isSkillsViewActive: true,
    },
    resolver: yupResolver(competenceSchema),
    mode: 'all',
  });

  const saveFields = useCallback(() => {
    const stepFormFields = methods.watch('competences');
    const newFields = { ...fields, competences: stepFormFields };
    dispatch({
      type: StepsTypes.SET_FIELDS,
      payload: {
        fields: {
          ...newFields,
        },
      },
    });
  }, [fields, dispatch]);

  useEffect(() => {
    const errorsL = Object.entries(methods.formState.errors).length;
    if (methods.formState.isSubmitted && errorsL > 0) {
      handleFormSnackbar(
        true,
        'Wähle bis zu 5 Kompetenzen, in denen Du über eine herausragende Expertise verfügst.',
      );
    }
  }, [methods.formState.submitCount]);

  const onSubmitOutside = (data: any) => {
    saveFields();
    handleStep(pretendStep);
  };

  const handleCompetencesSubmit = () => {
    if (methods.getValues('competences').length > 1) {
      methods.setValue('isSkillsViewActive', false);
      return;
    }

    const newStep = step + 1;
    saveFields();
    handleStep(newStep);
  };

  useEffect(() => {
    if (pretendStep !== 0) {
      methods.handleSubmit(onSubmitOutside)();
      handlePretendStep(0);
    }
  }, [pretendStep]);

  return (
    <FormProvider {...methods}>
      {methods.watch('isSkillsViewActive') ? (
        <Competences
          saveFields={saveFields}
          methods={methods}
          handleStep={handleStep}
          step={step}
          onSubmit={handleCompetencesSubmit}
        />
      ) : (
        <SortCompetences
          saveFields={saveFields}
          competencesGroups={modules.competences}
          handleStep={handleStep}
          step={step}
          methods={methods}
          name={'competences'}
          handleCompletedStep={handleCompletedStep}
        />
      )}
    </FormProvider>
  );
};
