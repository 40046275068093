import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '../../../../Icons/Close-large.svg';
import { useForm, useFormContext } from 'react-hook-form';
import { SelectMuiBtn } from './SelectMuiBtn';
import { CheckboxesContent } from './Content/CheckboxesContent';
import { AccordionBtn } from './AccordionBtn';
import { CheckboxListItemApi } from '../../../../Types/ResponseType';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormModalStyles } from '../Styles/FormModalStyles';
import { CheckboxListItem } from '../../../../Types/FormFields';

export interface FormCheckboxModalProps {
  label: string;
  name: string;
  header: string;
  options?: CheckboxListItem[];
  subHeader?: string;
  contextBtn?: string;
  isLastModal?: boolean;
  outsideSwitchModal?: boolean;
}

export const FormCheckboxModal = (props: FormCheckboxModalProps): JSX.Element => {
  const { name, label, contextBtn, options, isLastModal, header, subHeader } = props;
  const classes = FormModalStyles();
  const { setValue, getValues } = useFormContext();
  const [open, setOpen] = useState(false);
  const mobileView = useMediaQuery('(max-width:1024px)');

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSave = useCallback(
    checkedCheckboxes => {
      setValue(name, checkedCheckboxes, { shouldValidate: true });
      handleClose();
    },
    [name],
  );

  const methods = useForm({
    defaultValues: {
      [name]: getValues(name),
    },
  });

  useEffect(() => {
    methods.setValue(name, getValues(name));
  }, [getValues(name), open]);

  const onSubmit = (data: any) => {
    handleSave(data[name]);
  };

  return (
    <Grid>
      {contextBtn === 'accordion' ? (
        <AccordionBtn
          name={name}
          handleOpen={handleOpen}
          label={label}
          isLastModal={isLastModal}
          options={options}
        />
      ) : (
        <SelectMuiBtn
          handleOpen={handleOpen}
          label={label}
          name={name}
          options={options as CheckboxListItem[]}
        />
      )}
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DialogTitle className={classes.dialogTitle}>
          <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
            <img src={CloseIcon} />
          </IconButton>
          {mobileView && (
            <Typography variant={'body2'} className={classes.header}>
              {header}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CheckboxesContent
            methods={methods}
            options={options as CheckboxListItemApi[]}
            header={header}
            subHeader={subHeader}
            name={name}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            variant={'outlined'}
            color={'secondary'}
            className={classes.cta}>
            Fertig
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
