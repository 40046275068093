import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import ArrowExpand from '../../../../Icons/Arrow-expand.svg';
import { CheckboxRadioListItem } from '../../../../Types/FormFields';
import { CheckboxListItemApi } from '../../../../Types/ResponseType';
import { isCheckboxChecked } from '../../../../Domain/Services/CheckboxesListServices';

interface AccordionBtnProps {
  handleOpen: () => void;
  label: string;
  name: string;
  options?: CheckboxListItemApi[];
  isLastModal: boolean | undefined;
}

const useStyles = makeStyles<Theme>((theme) => {
  return {
    modalBtn: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      textTransform: 'unset',
      justifyContent: 'space-between',
      color: '#000',
      padding: '12px 0',
      width: '100%',
      cursor: 'pointer',
      borderBottom: '1px solid #EFEFEF',
      '& .MuiInputBase-root.Mui-disabled': {
        cursor: 'unset',
      },
      '& input': {
        cursor: 'pointer',
      },
    },
    lastModalBtn: {
      borderBottom: 'unset',
    },
    btnContentWrapper: {
      display: 'flex',
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'left'
      },
    },
    checkedNumber: {
      borderRadius: 4,
      marginLeft: 12,
      color: '#fff',
      fontSize: 12,
      textAlign: 'center',
      width: 24,
      fontWeight: 500,
      backgroundColor: theme.palette.secondary.main,
    },
  };
});

export const AccordionBtn = (props: AccordionBtnProps): JSX.Element => {
  const { handleOpen, label, name, options, isLastModal } = props;
  const classes = useStyles();
  const { getValues } = useFormContext();

  const getCheckedRadioNum = useCallback(() => {
    const currentState = getValues(name);
    const checkedCheckboxes = currentState.filter((object: CheckboxRadioListItem) => object.isChecked);

    const currentOptions = currentState.filter((checkbox: CheckboxRadioListItem) => {
      return checkedCheckboxes.find((checked: CheckboxRadioListItem) => checked.label === checkbox.label);
    });
    return currentOptions.length;
  }, [name]);

  const getCheckedNumber = useCallback(() => {
    if (options) {
      const checkedCheckboxes = getValues(name);

      const currentOptions = options.filter((checkbox: CheckboxListItemApi) => {
        return isCheckboxChecked(checkedCheckboxes, checkbox);
      });
      const inputValue = currentOptions.length;
      return inputValue;
    }
  }, [name, options, getValues]);

  const getGroupCheckedNum = useCallback(() => {
    if (options) {
      return getCheckedNumber();
    } else return getCheckedRadioNum();
  }, [options]);

  return (
    <Button className={clsx(classes.modalBtn,
      { [classes.lastModalBtn]: isLastModal })}
            onClick={handleOpen}>
      <Box className={classes.btnContentWrapper}>
        <Typography variant={'body2'}>
          {label}
        </Typography>
        {getGroupCheckedNum() > 0 && (
          <Box className={classes.checkedNumber}>{getGroupCheckedNum()}</Box>
        )}
      </Box>
      <img src={ArrowExpand} />
    </Button>
  );
};
