import { FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FormCheckboxStyled } from './FormCheckbox';
import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxListItemApi } from '../../../Types/ResponseType';
import { CheckboxListItem } from '../../../Types/FormFields';
import {
  getCheckboxesAfterDelete,
  isCheckboxChecked,
} from '../../../Domain/Services/CheckboxesListServices';

interface CheckboxGroupProps {
  options: CheckboxListItemApi[];
  name: string;
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    formGroup: {
      marginBottom: 60,
      '&> .MuiFormControlLabel-root': {
        borderBottom: '1px solid #EFEFEF',
        marginLeft: 0,
        '&:last-child': {
          borderBottom: 'unset',
        },
      },
    },
    label: {
      width: '100%',
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '8px 0',
      margin: 0,
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },

      '& span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    labelChecked: {
      fontWeight: 500,
      fontSize: 16,
      color: '#000',
      '& span': {
        fontWeight: 600,
      },
    },
    labelCheckedLevel: {
      '&.MuiFormControlLabel-root': {
        borderBottom: 'unset',
      },
    },
    highlightedCheckbox: {
      borderBottom: '2px solid #EFEFEF !important',
    },
  };
});

const REMOTE_ID = 113;
export const CheckboxGroup = (props: CheckboxGroupProps): JSX.Element => {
  const classes = useStyles();
  const { options, name } = props;
  const { getValues, setValue, control } = useFormContext();
  const [onlyRemote, setOnlyRemote] = useState(false);

  const handleChange = (checkbox: CheckboxListItem) => {
    let newCheckedCheckboxes: Array<CheckboxListItem> = [];
    const checkedCheckboxes = getValues(name);
    const isChecked = isCheckboxChecked(checkedCheckboxes, checkbox);

    // @ts-ignore //bad typing
    if (checkbox.id === REMOTE_ID) {
      setOnlyRemote(!isChecked);
      if (!isChecked) {
        setValue(name, [checkbox]);
        return;
      }
    }

    isChecked
      ? (newCheckedCheckboxes = getCheckboxesAfterDelete(checkedCheckboxes, checkbox))
      : (newCheckedCheckboxes = [...getValues(name), checkbox]);

    setValue(name, newCheckedCheckboxes);
  };

  useEffect(() => {
    const checkedCheckboxes: Array<CheckboxListItem> = getValues(name);

    // @ts-ignore //bad typing
    if (checkedCheckboxes.some(checkbox => checkbox.id === REMOTE_ID)) {
      setOnlyRemote(true);
    }
  }, []);

  return (
    <FormGroup className={classes.formGroup}>
      {options.map((checkbox: CheckboxListItem, idx: number) => {
        return (
          <Controller
            name={name}
            control={control}
            key={idx}
            render={() => {
              const checkedCheckboxes = getValues(name);

              return (
                <>
                  <FormControlLabel
                    className={clsx(classes.label, {
                      [classes.labelChecked]: isCheckboxChecked(checkedCheckboxes, checkbox),
                      // @ts-ignore
                      [classes.highlightedCheckbox]: checkbox.id === REMOTE_ID,
                    })}
                    control={
                      <FormCheckboxStyled
                        color={'secondary'}
                        value={checkbox.id}
                        checked={isCheckboxChecked(checkedCheckboxes, checkbox)}
                        onChange={() => handleChange(checkbox)}
                        // @ts-ignore
                        disabled={onlyRemote && checkbox.id !== REMOTE_ID}
                      />
                    }
                    label={checkbox.label}
                  />
                </>
              );
            }}
          />
        );
      })}
    </FormGroup>
  );
};
