import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const ContactLeftPartStyles = makeStyles<Theme>(() => {

  return {
    root: {
      display: 'flex',
      marginLeft: 78,
      position: 'relative',
      bottom: 120
    },
    wrapperInfo: {
      display: 'flex',
    },
    emailWrapper: {
      marginRight: 24,
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    contactText: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#999999'
    },
  };
});
