import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { AppContext } from '../../../../Context/Context';
import { StepsTypes } from '../../../../Context/StepsReducer';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { SearchTextField } from '../../../../App/Shared/Form/Customized/SearchTextField';
import { CheckboxRadioGroups } from '../../../../App/Shared/Form/CheckboxRadioGroups/CheckboxRadioGroups';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { RegDataTypes } from '../../../../Types/ResponseType';
import { useFormSnackbar } from '../../../../Hooks/useFormSnackbar';
import { LanguageModel } from './interface';

interface LanguagesFormProps {
  handleStep: (value: number) => void;
  step: number;
  handlePretendStep: (value: number) => void;
  handleCompletedStep: (value: number) => void;
}

export interface SaveFieldsParams {
  label?: string;
  level?: string;
  isChecked?: boolean;
}

const languagesLevels = ['Grundkenntnisse', 'Gute Kenntnisse', 'Fließend', 'Muttersprache'];

export const LanguagesForm = (props: LanguagesFormProps): JSX.Element => {
  const classes = StepFormStyles();
  const { handleStep, step, handlePretendStep, handleCompletedStep } = props;
  const {
    state: { steps },
    dispatch,
  } = useContext(AppContext);
  const { fields, pretendStep, regData, completedStep } = steps;
  const { modules } = regData as RegDataTypes;
  const handleFormSnackbar = useFormSnackbar();

  const languagesOptions = modules.languages.map((language: string) => {
    return { label: language, level: '', isChecked: false };
  });

  const methods = useForm<LanguageModel>({
    defaultValues: {
      languages: fields.languages.length > 0 ? fields.languages : languagesOptions,
      filteredCheckboxes: [],
      search: '',
    },
    mode: 'all',
  });

  const saveFields = useCallback(
    (params?: SaveFieldsParams) => {
      const stepFormFields = methods.watch('languages');

      if (params?.label && params?.level && params?.isChecked !== undefined) {
        const { label, level, isChecked } = params;
        const updateLanguageIx = stepFormFields.findIndex(lang => lang.label === label);
        if (updateLanguageIx >= 0) {
          // foo.filter(;
          stepFormFields[updateLanguageIx] = {
            label,
            level,
            isChecked,
          };
        }
      }

      const newFields = { ...fields, languages: stepFormFields };

      dispatch({
        type: StepsTypes.SET_FIELDS,
        payload: {
          fields: {
            ...newFields,
          },
        },
      });
    },
    [fields, dispatch],
  );

  const onSubmitPrevious = (data: any) => {
    saveFields();
    const newStep = step - 1;
    handleStep(newStep);
  };

  const handlePreviousStep = useCallback(() => {
    methods.handleSubmit(onSubmitPrevious)();
  }, []);

  const onSubmit = (data: any) => {
    const newStep = step + 1;

    saveFields();
    handleStep(newStep);

    if (completedStep < step) {
      handleCompletedStep(step);
    }
  };

  const handleChangeSearch = () => {
    const searchValue = methods.getValues('search').toLowerCase();

    if (searchValue.length === 0) return methods.setValue('filteredCheckboxes', []);

    const languagesState = methods.getValues('languages');

    const languagesFiltered = languagesState.filter((language: any) =>
      language.label.toLowerCase().includes(searchValue),
    );

    methods.setValue('filteredCheckboxes', languagesFiltered);
  };

  useEffect(() => {
    const errorsL = Object.entries(methods.formState.errors).length;

    if (methods.formState.isSubmitted && errorsL > 0) {
      handleFormSnackbar(true, 'Bitte wähle mindestens eine Sprache aus');
    }
  }, [methods.formState.submitCount]);

  const onSubmitOutside = (data: any) => {
    saveFields();
    handleStep(pretendStep);
  };

  useEffect(() => {
    if (pretendStep !== 0) {
      methods.handleSubmit(onSubmitOutside)();
      handlePretendStep(0);
    }
  }, [pretendStep]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
        <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>
          <FormHeader label={'Sprachen'} />
          <Typography variant={'body2'} className={classes.subHeader}>
            Wähle Deine bevorzugten Projektsprachen aus.
          </Typography>
          <Grid container direction={'column'} className={classes.fieldsWrapper}>
            <Grid item className={classes.searchWrapper}>
              <SearchTextField
                name={'search'}
                placeholder={'z. B. „Englisch“'}
                filterHandleChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <CheckboxRadioGroups
                name={'languages'}
                radioOptions={languagesLevels}
                saveFields={saveFields}
              />
            </Grid>
          </Grid>
          <Button className={classes.nextBtn} type={'submit'}>
            Weiter
          </Button>
          <Button
            className={classes.backBtn}
            color={'primary'}
            variant={'outlined'}
            onClick={handlePreviousStep}>
            Zurück
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
