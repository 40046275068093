import React, { Fragment, useEffect, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CheckBoxWithSelection } from './CheckBoxSelection';
import { HandleUpdateParams, Technologies } from '../Steps/TechStack';
import { techStackOptions } from './SelectionRadio';
import { AddItem } from '../AddItem';

interface GroupItemsSelectionProps {
  groups: Technologies[];
  searchValue?: string;
  closeSelection(): void;
  updateItems(values: HandleUpdateParams): void;
  defaultGroups?: string[];
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    groupItemsWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: '100%',
      zIndex: 100,
      padding: 8,
      marginBottom: 20,
      transform: 'translateX(100%)',
      transition: 'all .4s',
      overflow: 'visible',
      backgroundColor: '#FFF',
    },
    groupItemsWrapperVisible: {
      transform: 'translateX(0)',
    },
    backButton: {
      position: 'sticky',
      top: 0,
      zIndex: 5,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 32,
      cursor: 'pointer',
      marginBottom: 10,
      backgroundColor: 'rgba(255,255,255,.85)',
      '& svg': {
        transform: 'rotate(90deg) translateY(4px)',
        width: 32,
        height: 32,
        marginRight: 5,
      },
    },
    groupHeadline: {
      fontWeight: 'bold',
      marginTop: 8,
    },
    label: {
      display: 'block',
      fontSize: 16,
      color: theme.palette.primary.main,
      padding: '10px 0',
      margin: 0,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
      '& .MuiIconButton-root': {
        padding: 0,
        marginRight: 12,
      },
    },
    labelOpenRadio: {
      paddingBottom: 0,
    },
    labelChecked: {
      fontWeight: 500,
      fontSize: 16,
      color: '#000',
      '& span': {
        fontWeight: 600,
      },
    },
    labelCheckedLevel: {
      '&.MuiFormControlLabel-root': {
        borderBottom: 'unset !important',
      },
    },
    levelWrapper: {
      paddingBottom: 10,
      borderBottom: '1px solid #EFEFEF',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    levelBtn: {
      textTransform: 'unset',
      padding: '0 5px',
      color: '#999',
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: 27,
    },
  };
});

export const GroupItemsSelection: React.FC<GroupItemsSelectionProps> = ({
  groups,
  defaultGroups,
  closeSelection,
  updateItems,
  searchValue,
}) => {
  const classes = useStyles();
  const isVisible = groups.length > 0;
  const hasSearch = searchValue;
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      if (isVisible || hasSearch) {
        const scrollHeight = (ref.current as HTMLElement)?.scrollHeight;
        (ref.current as HTMLElement).style.height = `${scrollHeight}px`;
      } else {
        (ref.current as HTMLElement).style.height = '';
      }
    }
  }, [ref, isVisible, hasSearch]);

  return (
    <div
      className={clsx(classes.groupItemsWrapper, {
        [classes.groupItemsWrapperVisible]: isVisible || hasSearch,
      })}
      ref={ref}>
      {isVisible && (
        <>
          <div onClick={closeSelection} className={classes.backButton}>
            <svg width="24px" height="24px" viewBox="0 0 24 24">
              <polygon
                fill="#000000"
                fillRule="nonzero"
                points="16.59 8.59 12 13.17 7.41 8.59 6 10 12 16 18 10"
              />
            </svg>
            <Typography variant="body2">
              <strong>Zur Übersicht</strong>
            </Typography>
          </div>
          {groups.map((group, ix) => (
            <Fragment key={`${group.groupLabel}-${ix}`}>
              {group.entries.length > 0 && groups.length > 1 && (
                <Typography variant="body2" className={classes.groupHeadline}>
                  {group.groupLabel}
                </Typography>
              )}
              {group.entries.map((groupItem, ix) => (
                <CheckBoxWithSelection
                  key={`${groupItem.id}-${group.groupLabel}-${ix}`}
                  parent={group.groupLabel}
                  label={groupItem.label}
                  checked={!!groupItem.level}
                  onUpdate={updateItems}
                  id={groupItem.id}
                  value={
                    (!!groupItem.level &&
                      techStackOptions.filter(option => option.id === groupItem.level)?.[0]
                        ?.label) ||
                    ''
                  }
                />
              ))}
            </Fragment>
          ))}
        </>
      )}
      {hasSearch && (
        <AddItem
          group={groups.length === 1 ? groups[0]?.groupLabel : ''}
          searchValue={searchValue}
          defaultGroups={defaultGroups || []}
          addItem={updateItems}
        />
      )}
    </div>
  );
};
