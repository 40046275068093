import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import { FormHeader } from '../../../../App/Shared/Form/Header/FormHeader';
import { SortCompetencesList } from './SortCompetencesList';
import { GroupApi, RegDataTypes } from '../../../../Types/ResponseType';
import { StepFormStyles } from '../../Styles/StepFormStyles';
import { AppContext } from '../../../../Context/Context';

interface SortCompetencesProps {
  competencesGroups: GroupApi[];
  handleStep: (value: number) => void;
  step: number;
  methods: any;
  name: string;
  saveFields: () => void;
  handleCompletedStep: (value: number) => void;
}

export const SortCompetences = (props: SortCompetencesProps): JSX.Element => {
  const classes = StepFormStyles();
  const { handleStep, step, methods, name, saveFields, handleCompletedStep } = props;
  const {
    state: { steps },
  } = useContext(AppContext);
  const { regData, completedStep } = steps;
  const { modules } = regData as RegDataTypes;

  const handlePreviousStep = useCallback(() => {
    methods.setValue('isSkillsViewActive', true);
  }, [methods]);

  const onSubmit = (data: any) => {
    const newStep = step + 1;

    saveFields();
    handleStep(newStep);

    if (completedStep < step) {
      handleCompletedStep(step);
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Grid className={classes.root} container item direction={'column'} lg={6} md={7}>
        <FormHeader label={'Expertisen priorisieren'} />
        <Typography variant={'body2'} className={classes.subHeader}>
          Sortiere Deine Fachexpertisen beginnend mit Deiner herausragendsten.
        </Typography>
        <Grid container direction={'column'} className={classes.fieldsWrapper}>
          <SortCompetencesList name={name} />
        </Grid>
        <Button className={classes.nextBtn} type={'submit'}>
          <Box className={classes.btnContentWrapper}>Weiter</Box>
        </Button>
        <Button
          className={classes.backBtn}
          color={'primary'}
          variant={'outlined'}
          onClick={handlePreviousStep}>
          Zurück
        </Button>
      </Grid>
    </form>
  );
};
