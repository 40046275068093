import React from 'react';

export const IconArrowExpand: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g fill="none">
      <g transform="translate(-896.000000, -256.000000)">
        <g transform="translate(520.000000, 244.000000)">
          <g transform="translate(388.000000, 24.000000) rotate(-90.000000) translate(-388.000000, -24.000000) translate(376.000000, 12.000000)">
            <polygon fill="#000" points="16.59 8.59 12 13.17 7.41 8.59 6 10 12 16 18 10"></polygon>
            <polygon points="0 0 24 0 24 24 0 24" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
