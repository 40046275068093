import { ShortRegModel } from './interface';

export const shortRegInitialValues: ShortRegModel = {
  firstName: '',
  surname: '',
  email: '',
  profileUrl: '',
  uploadedGenDocs: [],
  password: '',
  passwordConfirmation: '',
  isGdpr: false,
  isFreelancer: false,
};
