
import TextField from '@material-ui/core/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { FormTextFieldStyles } from './Styles/FormTextFieldStyles';
import Info from '../../../Icons/Info.svg';
import { CustomTooltip } from './Tooltip/CustomTooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Autocomplete} from "@material-ui/lab";

interface FormInputTextProps {
  name: string;
  label: string;
  type?: string;
  tooltipTitle?: string;
  placeholder?: string;
}

export const FormTextFieldStyled = FormTextFieldStyles(Autocomplete);

export const FormAutocomplete = (props: FormInputTextProps) => {
  const { name, label, type = 'text', placeholder } = props;
  const { control, setValue } = useFormContext();
  const mobileView = useMediaQuery('(max-width:1024px)');

  const areaCodes = ['+49','+43','+41'];
  const value = '+49';

  const handleChange = (value: string) => {
    setValue('areaCode', value);
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { invalid, error } }) => {
        return (
          <>
            <FormTextFieldStyled
              color={'secondary'}
              options={areaCodes}
              freeSolo
              disableClearable
              blurOnSelect
              onInputChange={(_e,value) => {
                handleChange(value);
              }}
              value={value}
              placeholder={placeholder ? placeholder : undefined}
              className={value.length > 0  && !invalid  ? 'filledInput' : ''}
              renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={invalid} inputProps={{ ...params.inputProps, maxLength: 3}}  />}
            />
          </>
        );
      }}
   />
  );
};
